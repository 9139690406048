export default class A {
  constructor(t, e) {
    (this.database = t),
      (this.dd = e),
      (this.parent = "undefined" == typeof window ? self : window),
      (this.database = t),
      (this.dd = e);
  }
  fd() {
    if ("indexedDB" in this.parent) return this.parent.indexedDB;
  }
  isSupported() {
    var t;
    try {
      if (null == this.fd()) return !1;
      {
        const e =
          null === (t = this.fd()) || void 0 === t
            ? void 0
            : t.open("Braze IndexedDB Support Test");
        if (
          (e &&
            ((e.onupgradeneeded = () => e.result.close()),
            (e.onsuccess = () => e.result.close())),
          "undefined" != typeof window)
        ) {
          const t = window,
            e = t.chrome || t.browser || t.ad;
          if (e && e.runtime && e.runtime.id)
            return (
              this.dd.info(
                "Not using IndexedDB for storage because we are running inside an extension",
              ),
              !1
            );
        }
        return !0;
      }
    } catch (t) {
      return (
        this.dd.info(
          "Not using IndexedDB for storage due to following error: " + t,
        ),
        !1
      );
    }
  }
  vd(t, e) {
    var n;
    const o =
      null === (n = this.fd()) || void 0 === n
        ? void 0
        : n.open(this.database.Dd, this.database.VERSION);
    if (null == o) return "function" == typeof e && e(), !1;
    const i = this;
    return (
      (o.onupgradeneeded = (t) => {
        var e;
        i.dd.info(
          "Upgrading indexedDB " +
            i.database.Dd +
            " to v" +
            i.database.VERSION +
            "...",
        );
        const n = null === (e = t.target) || void 0 === e ? void 0 : e.result;
        for (const t in i.database.ss) {
          const e = t;
          i.database.ss.hasOwnProperty(t) &&
            !n.objectStoreNames.contains(i.database.ss[e]) &&
            n.createObjectStore(i.database.ss[e]);
        }
      }),
      (o.onsuccess = (n) => {
        var o;
        const r = null === (o = n.target) || void 0 === o ? void 0 : o.result;
        (r.onversionchange = () => {
          r.close(),
            "function" == typeof e && e(),
            i.dd.error(
              "Needed to close the database unexpectedly because of an upgrade in another tab",
            );
        }),
          t(r);
      }),
      (o.onerror = (t) => {
        var n;
        const o = t;
        return (
          i.dd.info(
            "Could not open indexedDB " +
              i.database.Dd +
              " v" +
              i.database.VERSION +
              ": " +
              (null === (n = o.target) || void 0 === n ? void 0 : n.errorCode),
          ),
          "function" == typeof e && e(),
          !0
        );
      }),
      !0
    );
  }
  setItem(t, e, n, o, i) {
    if (!this.isSupported()) return "function" == typeof i && i(), !1;
    const r = this;
    return this.vd((d) => {
      if (!d.objectStoreNames.contains(t))
        return (
          r.dd.error(
            "Could not store object " +
              e +
              " in " +
              t +
              " on indexedDB " +
              r.database.Dd +
              " - " +
              t +
              " is not a valid objectStore",
          ),
          "function" == typeof i && i(),
          void d.close()
        );
      const s = d.transaction([t], "readwrite");
      s.oncomplete = () => d.close();
      const u = s.objectStore(t).put(n, e);
      (u.onerror = () => {
        r.dd.error(
          "Could not store object " +
            e +
            " in " +
            t +
            " on indexedDB " +
            r.database.Dd,
        ),
          "function" == typeof i && i();
      }),
        (u.onsuccess = () => {
          "function" == typeof o && o();
        });
    }, i);
  }
  getItem(t, e, n) {
    if (!this.isSupported()) return !1;
    const o = this;
    return this.vd((i) => {
      if (!i.objectStoreNames.contains(t))
        return (
          o.dd.error(
            "Could not retrieve object " +
              e +
              " in " +
              t +
              " on indexedDB " +
              o.database.Dd +
              " - " +
              t +
              " is not a valid objectStore",
          ),
          void i.close()
        );
      const r = i.transaction([t], "readonly");
      r.oncomplete = () => i.close();
      const d = r.objectStore(t).get(e);
      (d.onerror = () => {
        o.dd.error(
          "Could not retrieve object " +
            e +
            " in " +
            t +
            " on indexedDB " +
            o.database.Dd,
        );
      }),
        (d.onsuccess = (t) => {
          var e;
          const o = null === (e = t.target) || void 0 === e ? void 0 : e.result;
          null != o && n(o);
        });
    });
  }
  jr(t, e, n) {
    if (!this.isSupported()) return "function" == typeof n && n(), !1;
    const o = this;
    return this.vd((i) => {
      if (!i.objectStoreNames.contains(t))
        return (
          o.dd.error(
            "Could not retrieve last record from " +
              t +
              " on indexedDB " +
              o.database.Dd +
              " - " +
              t +
              " is not a valid objectStore",
          ),
          "function" == typeof n && n(),
          void i.close()
        );
      const r = i.transaction([t], "readonly");
      r.oncomplete = () => i.close();
      const d = r.objectStore(t).openCursor(null, "prev");
      (d.onerror = () => {
        o.dd.error(
          "Could not open cursor for " + t + " on indexedDB " + o.database.Dd,
        ),
          "function" == typeof n && n();
      }),
        (d.onsuccess = (t) => {
          var o;
          const i = null === (o = t.target) || void 0 === o ? void 0 : o.result;
          null != i && null != i.value && null != i.key
            ? e(i.key, i.value)
            : "function" == typeof n && n();
        });
    }, n);
  }
  re(t, e) {
    if (!this.isSupported()) return !1;
    const n = this;
    return this.vd((o) => {
      if (!o.objectStoreNames.contains(t))
        return (
          n.dd.error(
            "Could not delete record " +
              e +
              " from " +
              t +
              " on indexedDB " +
              n.database.Dd +
              " - " +
              t +
              " is not a valid objectStore",
          ),
          void o.close()
        );
      const i = o.transaction([t], "readwrite");
      i.oncomplete = () => o.close();
      i.objectStore(t).delete(e).onerror = () => {
        n.dd.error(
          "Could not delete record " +
            e +
            " from " +
            t +
            " on indexedDB " +
            n.database.Dd,
        );
      };
    });
  }
  Zt(t, e) {
    if (!this.isSupported()) return !1;
    const n = this;
    return this.vd((o) => {
      if (!o.objectStoreNames.contains(t))
        return (
          n.dd.error(
            "Could not retrieve objects from " +
              t +
              " on indexedDB " +
              n.database.Dd +
              " - " +
              t +
              " is not a valid objectStore",
          ),
          void o.close()
        );
      const i = o.transaction([t], "readwrite");
      i.oncomplete = () => o.close();
      const r = i.objectStore(t),
        d = r.openCursor(),
        s = [];
      (d.onerror = () => {
        s.length > 0
          ? (n.dd.info(
              "Cursor closed midway through for " +
                t +
                " on indexedDB " +
                n.database.Dd,
            ),
            e(s))
          : n.dd.error(
              "Could not open cursor for " +
                t +
                " on indexedDB " +
                n.database.Dd,
            );
      }),
        (d.onsuccess = (t) => {
          var n;
          const o = null === (n = t.target) || void 0 === n ? void 0 : n.result;
          if (null != o) {
            if (null != o.value && null != o.key) {
              r.delete(o.key).onsuccess = () => {
                s.push(o.value);
              };
            }
            "function" == typeof o.continue && o.continue();
          } else s.length > 0 && e(s);
        });
    });
  }
  clearData() {
    if (!this.isSupported()) return !1;
    const t = [];
    for (const e in this.database.ss) {
      const n = e;
      this.database.ss.hasOwnProperty(e) &&
        this.database.ss[n] !== this.database.ss.ae &&
        t.push(this.database.ss[n]);
    }
    const e = this;
    return this.vd(function (n) {
      const o = n.transaction(t, "readwrite");
      o.oncomplete = () => n.close();
      for (let n = 0; n < t.length; n++) {
        const i = t[n];
        o.objectStore(i).clear().onerror = function () {
          e.dd.error(
            "Could not clear " +
              this.source.name +
              " on indexedDB " +
              e.database.Dd,
          );
        };
      }
      o.onerror = function () {
        e.dd.error(
          "Could not clear object stores on indexedDB " + e.database.Dd,
        );
      };
    });
  }
}
A.Yt = {
  Qt: {
    Dd: "AppboyServiceWorkerAsyncStorage",
    VERSION: 6,
    ss: {
      Qa: "data",
      hr: "pushClicks",
      cu: "pushSubscribed",
      pd: "fallbackDevice",
      ts: "cardUpdates",
      ae: "optOut",
      wr: "pendingData",
      xh: "sdkAuthenticationSignature",
    },
    ie: 1,
  },
};
