import { STORAGE_KEYS as o } from "./storage-manager.js";
import T from "./subscription-manager.js";
import { logger as r, IndexedDBAdapter as A } from "../../shared-lib/index.js";
export default class kt {
  constructor(t, i, s) {
    (this.u = t),
      (this.Zn = i),
      (this.gh = s),
      (this.u = t),
      (this.Zn = i || !1),
      (this.gh = s),
      (this.ph = new T()),
      (this.Fh = 0),
      (this.kh = 1);
  }
  fh() {
    return this.Zn;
  }
  wh() {
    return this.u.j(o.C.jh);
  }
  setSdkAuthenticationSignature(t) {
    const i = this.wh();
    this.u.k(o.C.jh, t);
    const s = A.Yt.Qt;
    new A(s, r).setItem(s.ss.xh, this.kh, t), i !== t && this.Xs();
  }
  qh() {
    this.u.hi(o.C.jh);
    const t = A.Yt.Qt;
    new A(t, r).re(t.ss.xh, this.kh);
  }
  subscribeToSdkAuthenticationFailures(t) {
    return this.gh.It(t);
  }
  yh(t) {
    this.gh.Dt(t);
  }
  Bh() {
    this.ph.removeAllSubscriptions();
  }
  Gh() {
    this.Fh += 1;
  }
  Hh() {
    return this.Fh;
  }
  Xs() {
    this.Fh = 0;
  }
}
