import { Guid as p, logger as r } from "../../shared-lib/index.js";
import { isArray as j } from "../util/code-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class _t {
  constructor(t, i, r) {
    (this.eu = t),
      null == t && (t = p.W()),
      !r || isNaN(r) ? (this.vl = new Date().valueOf()) : (this.vl = r),
      (this.eu = t),
      (this.Hl = new Date().valueOf()),
      (this.zl = i);
  }
  Y() {
    return `g:${this.eu}|e:${this.zl}|c:${this.vl}|l:${this.Hl}`;
  }
  static En(t) {
    let i;
    if ("string" == typeof t) {
      const e = t.split("|");
      if (!j(e) || 4 !== e.length) return null;
      try {
        const t = (t) => t.split(":")[1],
          r = (i) => {
            const r = parseInt(t(i));
            if (!isNaN(r)) return r;
          };
        (i = new _t(t(e[0]), r(e[1]), r(e[2]))), (i.Hl = r(e[3]));
      } catch (i) {
        r.info(
          `Unable to parse cookie string ${t}, failed with error: ${si(i)}`,
        );
      }
    } else {
      if (null == t || null == t.g) return null;
      (i = new _t(t.g, t.e, t.c)), (i.Hl = t.l);
    }
    return i;
  }
}
