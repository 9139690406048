import e, { OPTIONS as L } from "../../managers/braze-instance.js";
import X from "../../util/browser-detector.js";
import {
  clickElement as Et,
  supportsPassive as Tt,
} from "../../util/dom-utils.js";
import { KeyCodes as at } from "../../util/key-codes.js";
import T from "../../managers/subscription-manager.js";
import { isIFrame as It } from "../utils/in-app-message-utils.js";
export default class InAppMessage {
  constructor(
    t,
    s,
    i,
    h,
    e,
    E,
    n,
    o,
    r,
    l,
    u,
    a,
    I,
    c,
    O,
    A,
    L,
    _,
    m,
    N,
    R,
    S,
    D,
    M,
    C,
    d,
    U,
    b,
    P,
  ) {
    (this.message = t),
      (this.messageAlignment = s),
      (this.slideFrom = i),
      (this.extras = h),
      (this.triggerId = e),
      (this.clickAction = E),
      (this.uri = n),
      (this.openTarget = o),
      (this.dismissType = r),
      (this.duration = l),
      (this.icon = u),
      (this.imageUrl = a),
      (this.imageStyle = I),
      (this.iconColor = c),
      (this.iconBackgroundColor = O),
      (this.backgroundColor = A),
      (this.textColor = L),
      (this.closeButtonColor = _),
      (this.animateIn = m),
      (this.animateOut = N),
      (this.header = R),
      (this.headerAlignment = S),
      (this.headerTextColor = D),
      (this.frameColor = M),
      (this.buttons = C),
      (this.cropType = d),
      (this.orientation = U),
      (this.htmlId = b),
      (this.css = P),
      (this.message = t),
      (this.messageAlignment = s || InAppMessage.TextAlignment.CENTER),
      (this.duration = l || 5e3),
      (this.slideFrom = i || InAppMessage.SlideFrom.BOTTOM),
      (this.extras = h || {}),
      (this.triggerId = e),
      (this.clickAction = E || InAppMessage.ClickAction.NONE),
      (this.uri = n),
      (this.openTarget = o || InAppMessage.OpenTarget.NONE),
      (this.dismissType = r || InAppMessage.DismissType.AUTO_DISMISS),
      (this.icon = u),
      (this.imageUrl = a),
      (this.imageStyle = I || InAppMessage.ImageStyle.TOP),
      (this.iconColor = c || InAppMessage.th.ih),
      (this.iconBackgroundColor = O || InAppMessage.th.sh),
      (this.backgroundColor = A || InAppMessage.th.ih),
      (this.textColor = L || InAppMessage.th.hh),
      (this.closeButtonColor = _ || InAppMessage.th.eh),
      (this.animateIn = m),
      null == this.animateIn && (this.animateIn = !0),
      (this.animateOut = N),
      null == this.animateOut && (this.animateOut = !0),
      (this.header = R),
      (this.headerAlignment = S || InAppMessage.TextAlignment.CENTER),
      (this.headerTextColor = D || InAppMessage.th.hh),
      (this.frameColor = M || InAppMessage.th.Eh),
      (this.buttons = C || []),
      (this.cropType = d || InAppMessage.CropType.FIT_CENTER),
      (this.orientation = U),
      (this.htmlId = b),
      (this.css = P),
      (this.isControl = !1),
      (this.nh = !1),
      (this.Th = !1),
      (this.vo = !1),
      (this.oh = !1),
      (this.Pe = null),
      (this.we = null),
      (this.Et = new T()),
      (this.rh = new T()),
      (this.$e = InAppMessage.TextAlignment.CENTER);
  }
  subscribeToClickedEvent(t) {
    return this.Et.It(t);
  }
  subscribeToDismissedEvent(t) {
    return this.rh.It(t);
  }
  removeSubscription(t) {
    this.Et.removeSubscription(t), this.rh.removeSubscription(t);
  }
  removeAllSubscriptions() {
    this.Et.removeAllSubscriptions(), this.rh.removeAllSubscriptions();
  }
  closeMessage() {
    this.he(this.Pe);
  }
  xe() {
    return !0;
  }
  do() {
    return this.xe();
  }
  ve() {
    return null != this.htmlId && this.htmlId.length > 4;
  }
  be() {
    return this.ve() && null != this.css && this.css.length > 0;
  }
  ye() {
    if (this.ve() && this.be()) return this.htmlId + "-css";
  }
  K() {
    return !this.Th && ((this.Th = !0), !0);
  }
  po() {
    return this.Th;
  }
  p(t) {
    return !this.vo && ((this.vo = !0), this.Et.Dt(), !0);
  }
  N() {
    return !this.oh && ((this.oh = !0), this.rh.Dt(), !0);
  }
  hide(t) {
    if (t && t.parentNode) {
      let s = t.closest(".ab-iam-root");
      if ((null == s && (s = t), this.xe() && null != s.parentNode)) {
        const t = s.parentNode.classList;
        t && t.contains(InAppMessage.lh) && t.remove(InAppMessage.lh),
          document.body.removeEventListener("touchmove", InAppMessage.uh);
      }
      s.className = s.className.replace(InAppMessage.ah, InAppMessage.Ih);
    }
    return this.animateOut || !1;
  }
  he(t, s) {
    if (null == t) return;
    let i;
    (this.Pe = null),
      (i =
        -1 === t.className.indexOf("ab-in-app-message")
          ? t.getElementsByClassName("ab-in-app-message")[0]
          : t);
    let h = !1;
    i && (h = this.hide(i));
    const e = document.body;
    let E;
    null != e && (E = e.scrollTop);
    const n = () => {
      if (t && t.parentNode) {
        let s = t.closest(".ab-iam-root");
        null == s && (s = t), s.parentNode && s.parentNode.removeChild(s);
      }
      const i = this.ye();
      if (null != i) {
        const t = document.getElementById(i);
        t && t.parentNode && t.parentNode.removeChild(t);
      }
      null != e && "Safari" === X.browser && (e.scrollTop = E),
        s ? s() : this.N();
    };
    h ? setTimeout(n, InAppMessage.Oh) : n(), this.we && this.we.focus();
  }
  Ie() {
    return document.createTextNode(this.message || "");
  }
  ze(t) {
    let s = "";
    this.message || this.header || !this.xe() || (s = "Modal Image"),
      t.setAttribute("alt", s);
  }
  static uh(t) {
    if (t.targetTouches && t.targetTouches.length > 1) return;
    const s = t.target;
    (s &&
      s.classList &&
      s.classList.contains("ab-message-text") &&
      s.scrollHeight > s.clientHeight) ||
      (document.querySelector(`.${InAppMessage.lh}`) && t.preventDefault());
  }
  Ah(t) {
    const s = t.parentNode;
    this.xe() &&
      null != s &&
      this.orientation !== InAppMessage.Orientation.LANDSCAPE &&
      (null != s.classList && s.classList.add(InAppMessage.lh),
      document.body.addEventListener(
        "touchmove",
        InAppMessage.uh,
        !!Tt() && { passive: !1 },
      )),
      (t.className += " " + InAppMessage.ah);
  }
  static Lh(t) {
    if (
      t.keyCode === at._h &&
      !e.nn(L.mh) &&
      document.querySelectorAll(".ab-modal-interactions").length > 0
    ) {
      const t = document.getElementsByClassName("ab-html-message");
      let s = !1;
      for (const i of t) {
        let t = null;
        It(i) &&
          i.contentWindow &&
          (t = i.contentWindow.document.getElementsByClassName(
            "ab-programmatic-close-button",
          )[0]),
          null != t && (Et(t), (s = !0));
      }
      if (!s) {
        const t = document.querySelectorAll(
          ".ab-modal-interactions > .ab-close-button",
        )[0];
        null != t && Et(t);
      }
    }
  }
  Nh() {
    this.nh ||
      e.nn(L.mh) ||
      (document.addEventListener("keydown", InAppMessage.Lh, !1),
      e.Rh(() => {
        document.removeEventListener("keydown", InAppMessage.Lh);
      }),
      (this.nh = !0));
  }
  Y(t) {
    const s = {};
    return t
      ? ((s[InAppMessage.tt.ea] = this.message),
        (s[InAppMessage.tt.ra] = this.messageAlignment),
        (s[InAppMessage.tt.Sh] = this.slideFrom),
        (s[InAppMessage.tt.bt] = this.extras),
        (s[InAppMessage.tt.sa] = this.triggerId),
        (s[InAppMessage.tt.ta] = this.clickAction),
        (s[InAppMessage.tt.URI] = this.uri),
        (s[InAppMessage.tt.ia] = this.openTarget),
        (s[InAppMessage.tt.aa] = this.dismissType),
        (s[InAppMessage.tt.oa] = this.duration),
        (s[InAppMessage.tt.pa] = this.icon),
        (s[InAppMessage.tt.ot] = this.imageUrl),
        (s[InAppMessage.tt.ma] = this.imageStyle),
        (s[InAppMessage.tt.na] = this.iconColor),
        (s[InAppMessage.tt.ua] = this.iconBackgroundColor),
        (s[InAppMessage.tt.ca] = this.backgroundColor),
        (s[InAppMessage.tt.fa] = this.textColor),
        (s[InAppMessage.tt.da] = this.closeButtonColor),
        (s[InAppMessage.tt.la] = this.animateIn),
        (s[InAppMessage.tt.ga] = this.animateOut),
        (s[InAppMessage.tt.ja] = this.header),
        (s[InAppMessage.tt.xa] = this.headerAlignment),
        (s[InAppMessage.tt.za] = this.headerTextColor),
        (s[InAppMessage.tt.ha] = this.frameColor),
        (s[InAppMessage.tt.va] = this.buttons),
        (s[InAppMessage.tt.wa] = this.cropType),
        (s[InAppMessage.tt.ya] = this.orientation),
        (s[InAppMessage.tt.Sa] = this.htmlId),
        (s[InAppMessage.tt.CSS] = this.css),
        (s[InAppMessage.tt.Z] = t),
        s)
      : s;
  }
}
(InAppMessage.th = {
  hh: 4281545523,
  ih: 4294967295,
  sh: 4278219733,
  Dh: 4293914607,
  Mh: 4283782485,
  Eh: 3224580915,
  eh: 4288387995,
}),
  (InAppMessage.Me = {
    Ch: "hd",
    ke: "ias",
    dh: "of",
    Uh: "do",
    Lr: "umt",
    Or: "tf",
    bh: "te",
  }),
  (InAppMessage.SlideFrom = { TOP: "TOP", BOTTOM: "BOTTOM" }),
  (InAppMessage.ClickAction = {
    NEWS_FEED: "NEWS_FEED",
    URI: "URI",
    NONE: "NONE",
  }),
  (InAppMessage.DismissType = {
    AUTO_DISMISS: "AUTO_DISMISS",
    MANUAL: "SWIPE",
  }),
  (InAppMessage.OpenTarget = { NONE: "NONE", BLANK: "BLANK" }),
  (InAppMessage.ImageStyle = { TOP: "TOP", GRAPHIC: "GRAPHIC" }),
  (InAppMessage.Orientation = { PORTRAIT: "PORTRAIT", LANDSCAPE: "LANDSCAPE" }),
  (InAppMessage.TextAlignment = {
    START: "START",
    CENTER: "CENTER",
    END: "END",
  }),
  (InAppMessage.CropType = {
    CENTER_CROP: "CENTER_CROP",
    FIT_CENTER: "FIT_CENTER",
  }),
  (InAppMessage.Ce = {
    rn: "SLIDEUP",
    sn: "MODAL",
    Je: "MODAL_STYLED",
    Xr: "FULL",
    Zr: "WEB_HTML",
    je: "HTML",
    Se: "HTML_FULL",
  }),
  (InAppMessage.Oh = 500),
  (InAppMessage.Ph = 200),
  (InAppMessage.ah = "ab-show"),
  (InAppMessage.Ih = "ab-hide"),
  (InAppMessage.lh = "ab-pause-scrolling"),
  (InAppMessage.tt = {
    ea: "m",
    ra: "ma",
    Sh: "sf",
    bt: "e",
    sa: "ti",
    ta: "ca",
    URI: "u",
    ia: "oa",
    aa: "dt",
    oa: "d",
    pa: "i",
    ot: "iu",
    ma: "is",
    na: "ic",
    ua: "ibc",
    ca: "bc",
    fa: "tc",
    da: "cbc",
    la: "ai",
    ga: "ao",
    ja: "h",
    xa: "ha",
    za: "htc",
    ha: "fc",
    va: "b",
    wa: "ct",
    ya: "o",
    Sa: "hi",
    CSS: "css",
    Z: "type",
    uo: "messageFields",
  });
