import InAppMessage from "../models/in-app-message.js";
import { _handleBrazeAction as u } from "../../Core/handle-braze-action.js";
import { logInAppMessageButtonClick } from "../log-in-app-message-button-click.js";
import { toRgba as ie } from "../../util/color-utils.js";
import { addPassiveEventListener as oe } from "../../util/dom-utils.js";
import { KeyCodes as at } from "../../util/key-codes.js";
const le = {
  qe: (t) => {
    const o = t.querySelectorAll(
      ".ab-close-button, .ab-message-text, .ab-message-button",
    );
    let e;
    for (let t = 0; t < o.length; t++) (e = o[t]), (e.tabIndex = 0);
    if (o.length > 0) {
      const e = o[0],
        s = o[o.length - 1];
      t.addEventListener("keydown", (o) => {
        const a = document.activeElement;
        o.keyCode === at.lo &&
          (o.shiftKey || (a !== s && a !== t)
            ? !o.shiftKey ||
              (a !== e && a !== t) ||
              (o.preventDefault(), s.focus())
            : (o.preventDefault(), e.focus()));
      });
    }
  },
  Be: (t, o) => {
    o.setAttribute("role", "dialog"),
      o.setAttribute("aria-modal", "true"),
      o.setAttribute("aria-label", "Modal Message"),
      t && o.setAttribute("aria-labelledby", t);
  },
  Te: (t, o, e, s) => {
    if (t.buttons && t.buttons.length > 0) {
      const a = document.createElement("div");
      (a.className = "ab-message-buttons"), e.appendChild(a);
      const l = e.getElementsByClassName("ab-message-text")[0];
      null != l && (l.className += " ab-with-buttons");
      const n = (a) => (l) => (
        t.he(e, () => {
          logInAppMessageButtonClick(a, t),
            a.clickAction === InAppMessage.ClickAction.URI
              ? u(
                  a.uri || "",
                  s || t.openTarget === InAppMessage.OpenTarget.BLANK,
                  l,
                )
              : a.clickAction === InAppMessage.ClickAction.NEWS_FEED && o();
        }),
        l.stopPropagation(),
        !1
      );
      for (let o = 0; o < t.buttons.length; o++) {
        const e = t.buttons[o],
          s = document.createElement("button");
        (s.className = "ab-message-button"),
          s.setAttribute("type", "button"),
          oe(s, "touchstart");
        let l = e.text;
        "" === e.text && (l = " "),
          s.appendChild(document.createTextNode(l)),
          t.be() ||
            ((s.style.backgroundColor = ie(e.backgroundColor)),
            (s.style.color = ie(e.textColor)),
            (s.style.borderColor = ie(e.borderColor))),
          (s.onclick = n(e)),
          a.appendChild(s);
      }
    }
  },
};
export default le;
