import {
  dateFromUnixTimestamp as l,
  rehydrateDateAfterJsonization as w,
} from "../../util/date-utils.js";
import { logger as r } from "../../../shared-lib/index.js";
import ri from "./trigger-condition.js";
import { validateValueIsFromEnum as J } from "../../util/code-utils.js";
export default class pt {
  constructor(t, i = [], s, e, r = 0, h, l, o = 0, n = pt.Vu, a, u, d) {
    (this.id = t),
      (this.Wu = i),
      (this.startTime = s),
      (this.endTime = e),
      (this.priority = r),
      (this.type = h),
      (this.data = l),
      (this.Yu = o),
      (this.Zu = n),
      (this.Vr = a),
      (this.td = u),
      (this.sd = d),
      (this.id = t),
      (this.Wu = i || []),
      void 0 === s && (s = null),
      (this.startTime = s),
      void 0 === e && (e = null),
      (this.endTime = e),
      (this.priority = r || 0),
      (this.type = h),
      (this.Yu = o || 0),
      null == a && (a = 1e3 * (this.Yu + 30)),
      (this.Vr = a),
      (this.data = l),
      null != n && (this.Zu = n),
      (this.td = u),
      (this.sd = d || null);
  }
  ed(t) {
    return (
      null == this.sd || (this.Zu !== pt.Vu && t - this.sd >= 1e3 * this.Zu)
    );
  }
  rd(t) {
    this.sd = t;
  }
  hd(t) {
    const i = t + 1e3 * this.Yu;
    return Math.max(i - new Date().valueOf(), 0);
  }
  od(t) {
    const i = new Date().valueOf() - t,
      s = null == t || isNaN(i) || null == this.Vr || i < this.Vr;
    return (
      s ||
        r.info(
          `Trigger action ${this.type} is no longer eligible for display - fired ${i}ms ago and has a timeout of ${this.Vr}ms.`,
        ),
      !s
    );
  }
  static fromJson(t) {
    const i = t.id,
      s = [];
    for (let i = 0; i < t.trigger_condition.length; i++)
      s.push(ri.fromJson(t.trigger_condition[i]));
    const e = l(t.start_time),
      r = l(t.end_time),
      h = t.priority,
      o = t.type,
      n = t.delay,
      a = t.re_eligibility,
      u = t.timeout,
      d = t.data,
      m = t.min_seconds_since_last_trigger;
    return J(
      pt.Kr,
      o,
      "Could not construct Trigger from server data",
      "Trigger.Types",
    )
      ? new pt(i, s, e, r, h, o, d, n, a, u, m)
      : null;
  }
  Y() {
    const t = [];
    for (let i = 0; i < this.Wu.length; i++) t.push(this.Wu[i].Y());
    return {
      i: this.id,
      c: t,
      s: this.startTime,
      e: this.endTime,
      p: this.priority,
      t: this.type,
      da: this.data,
      d: this.Yu,
      r: this.Zu,
      tm: this.Vr,
      ss: this.td,
      ld: this.sd,
    };
  }
  static En(t) {
    const i = [],
      s = t.c || [];
    for (let t = 0; t < s.length; t++) i.push(ri.En(s[t]));
    return new pt(
      t.i,
      i,
      w(t.s),
      w(t.e),
      t.p,
      t.t,
      t.da,
      t.d,
      t.r,
      t.tm,
      t.ss,
      t.ld,
    );
  }
}
(pt.Kr = { Jr: "inapp", nd: "templated_iam" }), (pt.Vu = -1);
