import { buttonsFromSerializedInAppMessage as pe } from "../in-app-message-factory.js";
import InAppMessage from "./in-app-message.js";
export default class ModalMessage extends InAppMessage {
  constructor(
    e,
    r,
    s,
    t,
    i,
    o,
    a,
    p,
    m,
    n,
    u,
    c,
    d,
    f,
    l,
    g,
    j,
    v,
    x,
    z,
    h,
    w,
    y,
    S,
    b,
    k,
    q,
  ) {
    super(
      e,
      r,
      void 0,
      s,
      t,
      i,
      o,
      a,
      (p = p || InAppMessage.DismissType.MANUAL),
      m,
      n,
      u,
      c,
      d,
      f,
      l,
      g,
      j,
      v,
      x,
      z,
      h,
      w,
      y,
      S,
      (b = b || InAppMessage.CropType.FIT_CENTER),
      void 0,
      k,
      q,
    ),
      (this.$e = InAppMessage.TextAlignment.CENTER);
  }
  Y() {
    return super.Y(ModalMessage.it);
  }
  static Yr(e) {
    return new ModalMessage(
      e[InAppMessage.tt.ea],
      e[InAppMessage.tt.ra],
      e[InAppMessage.tt.bt],
      e[InAppMessage.tt.sa],
      e[InAppMessage.tt.ta],
      e[InAppMessage.tt.URI],
      e[InAppMessage.tt.ia],
      e[InAppMessage.tt.aa],
      e[InAppMessage.tt.oa],
      e[InAppMessage.tt.pa],
      e[InAppMessage.tt.ot],
      e[InAppMessage.tt.ma],
      e[InAppMessage.tt.na],
      e[InAppMessage.tt.ua],
      e[InAppMessage.tt.ca],
      e[InAppMessage.tt.fa],
      e[InAppMessage.tt.da],
      e[InAppMessage.tt.la],
      e[InAppMessage.tt.ga],
      e[InAppMessage.tt.ja],
      e[InAppMessage.tt.xa],
      e[InAppMessage.tt.za],
      e[InAppMessage.tt.ha],
      pe(e[InAppMessage.tt.va]),
      e[InAppMessage.tt.wa],
      e[InAppMessage.tt.Sa],
      e[InAppMessage.tt.CSS],
    );
  }
}
ModalMessage.it = InAppMessage.Ce.sn;
