import InAppMessage from "./in-app-message.js";
import { DOMUtils as de } from "../../util/dom-utils.js";
export default class SlideUpMessage extends InAppMessage {
  constructor(e, t, s, o, i, r, n, d, a, u, p, m, c, l, v, x, f, h, g, I, M) {
    (x = x || InAppMessage.th.Mh),
      (v = v || InAppMessage.th.Dh),
      super(
        e,
        (t = t || InAppMessage.TextAlignment.START),
        s,
        o,
        i,
        r,
        n,
        d,
        a,
        u,
        p,
        m,
        void 0,
        c,
        l,
        v,
        x,
        f,
        h,
        g,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        void 0,
        I,
        M,
      ),
      (this.$e = InAppMessage.TextAlignment.START);
  }
  xe() {
    return !1;
  }
  Ie() {
    const e = document.createElement("span");
    return e.appendChild(document.createTextNode(this.message || "")), e;
  }
  Ah(e) {
    const t = e.getElementsByClassName("ab-in-app-message")[0];
    de.co(t, !0, !0) ||
      (this.slideFrom === InAppMessage.SlideFrom.TOP
        ? (t.style.top = "0px")
        : (t.style.bottom = "0px")),
      super.Ah(e);
  }
  Y() {
    return super.Y(SlideUpMessage.it);
  }
  static Yr(e) {
    return new SlideUpMessage(
      e[InAppMessage.tt.ea],
      e[InAppMessage.tt.ra],
      e[InAppMessage.tt.Sh],
      e[InAppMessage.tt.bt],
      e[InAppMessage.tt.sa],
      e[InAppMessage.tt.ta],
      e[InAppMessage.tt.URI],
      e[InAppMessage.tt.ia],
      e[InAppMessage.tt.aa],
      e[InAppMessage.tt.oa],
      e[InAppMessage.tt.pa],
      e[InAppMessage.tt.ot],
      e[InAppMessage.tt.na],
      e[InAppMessage.tt.ua],
      e[InAppMessage.tt.ca],
      e[InAppMessage.tt.fa],
      e[InAppMessage.tt.da],
      e[InAppMessage.tt.la],
      e[InAppMessage.tt.ga],
      e[InAppMessage.tt.Sa],
      e[InAppMessage.tt.CSS],
    );
  }
}
SlideUpMessage.it = InAppMessage.Ce.rn;
