import C from "./util/net.js";
import ue from "./models/braze-event.js";
import s from "./common/event-logger.js";
import { randomInclusive as k } from "./util/math.js";
import t from "./models/request-result.js";
import {
  logger as r,
  IndexedDBAdapter as A,
  Guid as p,
  EventTypes as i,
} from "../shared-lib/index.js";
import { STORAGE_KEYS as o } from "./managers/storage-manager.js";
import T from "./managers/subscription-manager.js";
import vt from "./Push/utils/push-utils.js";
import S from "./util/request-header-utils.js";
export default class Lt {
  constructor(t, i, s, e, h, o, n, r, u, l) {
    (this.an = t),
      (this.baseUrl = i),
      (this.di = s),
      (this.hn = e),
      (this.wt = h),
      (this.yt = o),
      (this.u = n),
      (this.bu = r),
      (this.Go = u),
      (this.qt = l),
      (this.an = t),
      (this.baseUrl = i),
      (this.wu = 0),
      (this.gE = n.RE() || 0),
      (this.ku = null),
      (this.di = s),
      (this.hn = e),
      (this.wt = h),
      (this.yt = o),
      (this.u = n),
      (this.Go = u),
      (this.qt = l),
      (this.bu = r),
      (this.yu = new T()),
      (this.ju = null),
      (this.Su = 50),
      (this.Au = !1);
  }
  $u(t, i) {
    return !t && !i && this.Go.Hh() >= this.Su;
  }
  Cu(t) {
    let s = this.di.El();
    if (t.length > 0) {
      const e = this.wt.getUserId();
      for (const h of t) {
        const t = (!h.userId && !e) || h.userId === e;
        h.type === i.kl && t && (s = !0);
      }
    }
    return s;
  }
  Du(t = !1, i = !1, s = !0, e, h, n, u = !1, l = !1) {
    s && this.Fu();
    const a = this.u.TE(),
      c = this.u.AE();
    let d = !1;
    const f = (t, i) => {
        let r = !1;
        S.Os(this.u, S.Gs.Qa, new Date().valueOf()),
          C.Qs({
            url: this.baseUrl + "/data/",
            data: t,
            headers: i,
            L: (s) => {
              null != t.respond_with &&
                t.respond_with.triggers &&
                (this.wu = Math.max(this.wu - 1, 0)),
                this.qt.Ws(t, s, i)
                  ? (this.Go.Xs(),
                    this.yt.ul(s),
                    (null != t.respond_with &&
                      t.respond_with.user_id != this.wt.getUserId()) ||
                      (null != t.device && this.u.k(o.C.Ja, t.device),
                      null != t.sdk_metadata &&
                        (this.u.k(o.C.Na, t.sdk_metadata),
                        this.u.k(o.C.$a, this.di.ki())),
                      this.bu(s),
                      S.Ys(this.u, S.Gs.Qa, 1),
                      "function" == typeof e && e()))
                  : s.auth_error && (r = !0);
            },
            error: () => {
              null != t.respond_with &&
                t.respond_with.triggers &&
                (this.wu = Math.max(this.wu - 1, 0)),
                this.qt.Ea(t.events, t.attributes),
                "function" == typeof h && h();
            },
            ti: (t) => {
              if (("function" == typeof n && n(t), s && !d)) {
                if (t && !r) this.Tu();
                else {
                  S.si(this.u, S.Gs.Qa);
                  let t = this.ku;
                  (null == t || t < 1e3 * this.gE) && (t = 1e3 * this.gE),
                    this.Tu(Math.min(3e5, k(1e3 * this.gE, 3 * t)));
                }
                d = !0;
              }
            },
          });
      },
      m = this.Cu(a),
      g = i || m;
    if (this.$u(u, m))
      return void r.info(
        "Declining to flush data due to 50 consecutive authentication failures",
      );
    if (s && !this.qt.Wa(a, c, t, g))
      return this.Tu(), void ("function" == typeof n && n(!0));
    const v = this.qt.Ya(t, g, a, c, l);
    g && this.wu++;
    let p = !1;
    if (v)
      for (const t of v)
        this.qt.Hs(t.requestData, () => f(t.requestData, t.headers), S.ii.Qa),
          (p = !0);
    this.Go.fh() && s && !p
      ? this.Tu()
      : m && (r.info("Invoking new session subscriptions"), this.yu.Dt());
  }
  Lu() {
    return this.wu > 0;
  }
  Tu(t = 1e3 * this.gE) {
    this.Au ||
      (this.Fu(),
      (this.ju = window.setTimeout(() => {
        if (document.hidden) {
          const t = "visibilitychange",
            i = () => {
              document.hidden ||
                (document.removeEventListener(t, i, !1), this.Du());
            };
          document.addEventListener(t, i, !1);
        } else this.Du();
      }, t)),
      (this.ku = t));
  }
  Fu() {
    null != this.ju && (clearTimeout(this.ju), (this.ju = null));
  }
  initialize() {
    (this.Au = !1), this.Tu();
  }
  destroy() {
    this.yu.removeAllSubscriptions(),
      this.Go.Bh(),
      this.Fu(),
      (this.Au = !0),
      this.Du(void 0, void 0, !1, void 0, void 0, void 0, void 0, !0),
      (this.ju = null);
  }
  mr(t) {
    return this.yu.It(t);
  }
  openSession() {
    const t = this.di.ki() !== this.di.xo();
    t && (this.u.uE(o.iu.Ta), this.u.uE(o.iu.su)),
      this.Du(void 0, !1, void 0, () => {
        this.u.hi(o.C.Ii);
      }),
      this.Jn(),
      t &&
        import("./Push/push-manager-factory.js").then((t) => {
          if (this.Au) return;
          const i = t.default.m();
          if (
            null != i &&
            (vt.isPushPermissionGranted() || vt.isPushBlocked())
          ) {
            const t = () => {
                i.vn()
                  ? r.info(
                      "Push token maintenance is disabled, not refreshing token for backend.",
                    )
                  : i.subscribe();
              },
              s = (i, s) => {
                s && t();
              },
              e = () => {
                const i = this.u.j(o.C.zn);
                (null == i || i) && t();
              },
              h = A.Yt.Qt;
            new A(h, r).jr(h.ss.cu, s, e);
          }
        });
  }
  Mu() {
    this.u.hi(o.C.Ti), this.u.hi(o.C.Cs), this.u.hi(o.C.Wr);
  }
  changeUser(t, i, s) {
    const e = this.wt.getUserId();
    if (e !== t) {
      this.di.ql(),
        this.Mu(),
        null != e && this.Du(void 0, !1, void 0, void 0, void 0),
        this.wt.ou(t),
        s ? this.Go.setSdkAuthenticationSignature(s) : this.Go.qh();
      for (let t = 0; t < i.length; t++) i[t].changeUser(null == e);
      null != e && this.u.hi(o.C.J),
        this.u.hi(o.C.Ja),
        this.u.hi(o.C.ai),
        this.openSession(),
        r.info('Changed user to "' + t + '".');
    } else {
      let i = "Doing nothing.";
      s &&
        this.Go.wh() !== s &&
        (this.Go.setSdkAuthenticationSignature(s),
        (i = "Updated SDK authentication signature")),
        r.info(`Current user is already ${t}. ${i}`);
    }
  }
  requestImmediateDataFlush(t) {
    this.Fu(), this.di.xo();
    this.Du(
      void 0,
      void 0,
      void 0,
      void 0,
      () => {
        r.error("Failed to flush data, request will be retried automatically.");
      },
      t,
      !0,
    );
  }
  requestFeedRefresh() {
    this.di.xo(), this.Du(!0);
  }
  $r(t, i) {
    this.di.xo(),
      r.info("Requesting explicit trigger refresh."),
      this.Du(void 0, !0, void 0, t, i);
  }
  Fn(t, e) {
    const h = i.Pu,
      o = { a: t, l: e },
      n = s.q(h, o);
    return n && r.info(`Logged alias ${t} with label ${e}`), n;
  }
  Bn(i, e, h) {
    if (this.yt.hu(e))
      return (
        r.info(`Custom Attribute "${e}" is blocklisted, ignoring.`), new t()
      );
    const o = { key: e, value: h },
      n = s.q(i, o);
    if (n) {
      const t = "object" == typeof h ? JSON.stringify(h, null, 2) : h;
      r.info(`Logged custom attribute: ${e} with value: ${t}`);
    }
    return n;
  }
  setLastKnownLocation(t, e, h, o, n, u) {
    const l = { latitude: e, longitude: h };
    null != o && (l.altitude = o),
      null != n && (l.ll_accuracy = n),
      null != u && (l.alt_accuracy = u);
    const a = s.q(i.xu, l, t || void 0);
    return (
      a &&
        r.info(`Set user last known location as ${JSON.stringify(l, null, 2)}`),
      a
    );
  }
  kr(t, s) {
    const e = this.di.xo();
    return new ue(this.wt.getUserId(), i.Iu, t, e, { cid: s });
  }
  Nu(t, i) {
    return new A(t, i);
  }
  Jn() {
    const t = A.Yt.Qt;
    this.Nu(t, r).setItem(t.ss.Qa, 1, {
      baseUrl: this.baseUrl,
      data: { api_key: this.an, device_id: this.hn.ce().id },
      userId: this.wt.getUserId(),
      sdkAuthEnabled: this.Go.fh(),
    });
  }
  yr(t) {
    for (const i of t)
      if (i.api_key === this.an) this.qt.Ea(i.events, i.attributes);
      else {
        const t = A.Yt.Qt;
        new A(t, r).setItem(t.ss.wr, p.W(), i);
      }
  }
  Kn(e, h, o) {
    if (this.yt.hu(e))
      return (
        r.info(`Custom Attribute "${e}" is blocklisted, ignoring.`), new t()
      );
    let n, u;
    return (
      null === h && null === o
        ? ((n = i.Ou), (u = { key: e }))
        : ((n = i.Uu), (u = { key: e, latitude: h, longitude: o })),
      s.q(n, u)
    );
  }
  Mn(t, e) {
    const h = { group_id: t, status: e };
    return s.q(i._u, h);
  }
}
