import { logger as r } from "../../shared-lib/index.js";
import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import T from "../managers/subscription-manager.js";
import { randomInclusive as k } from "../util/math.js";
import C from "../util/net.js";
import {
  newFeatureFlagFromJson as st,
  newFeatureFlagFromSerializedValue as it,
} from "./feature-flag-factory.js";
import S from "../util/request-header-utils.js";
export default class er extends y {
  constructor(t, s, i, r) {
    super(),
      (this.yt = t),
      (this.qt = s),
      (this.u = i),
      (this.di = r),
      (this.mi = []),
      (this.gi = 0),
      (this.yt = t),
      (this.qt = s),
      (this.u = i),
      (this.di = r),
      (this.Fi = null),
      (this.vi = new T()),
      (this.pi = 10),
      (this.wi = null),
      (this.ji = null),
      e.$t(this.vi);
  }
  Rs(t) {
    var s;
    if (
      (null === (s = this.yt) || void 0 === s ? void 0 : s.yi()) &&
      null != t &&
      t.feature_flags
    ) {
      this.mi = [];
      for (const s of t.feature_flags) {
        const t = st(s);
        t && this.mi.push(t);
      }
      (this.gi = new Date().getTime()), this.bi(), this.vi.Dt(this.mi);
    }
  }
  Ci() {
    let t = {};
    this.u && (t = this.u.j(o.C.Ti));
    const s = {};
    for (const i in t) {
      const e = it(t[i]);
      e && (s[e.id] = e);
    }
    return s;
  }
  Di() {
    var t;
    return (null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.Ii)) || {};
  }
  Ri(t) {
    this.u && this.u.k(o.C.Ii, t);
  }
  ei(t) {
    return this.vi.It(t);
  }
  refreshFeatureFlags(t, s, i = !1, e = !0) {
    const r = () => {
      "function" == typeof s && s(), this.vi.Dt(this.mi);
    };
    if (!this.Si(i))
      return (
        !this.Fi &&
          this.yt &&
          (this.Fi = this.yt.xi(() => {
            this.refreshFeatureFlags(t, s);
          })),
        void r()
      );
    if ((e && this.Ni(), !this.qt)) return void r();
    const h = this.qt.Ps({}, !0),
      o = this.qt._s(h, S.Gs.qi);
    let a = !1;
    this.qt.Hs(
      h,
      () => {
        this.qt
          ? (S.Os(this.u, S.Gs.qi, new Date().valueOf()),
            C.Qs({
              url: `${this.qt.Vs()}/feature_flags/sync`,
              headers: o,
              data: h,
              L: (s) => {
                if (!this.qt.Ws(h, s, o)) return (a = !0), void r();
                this.qt.Xs(),
                  this.Rs(s),
                  (a = !1),
                  S.Ys(this.u, S.Gs.qi, 1),
                  "function" == typeof t && t();
              },
              error: (t) => {
                this.qt.Zs(t, "retrieving feature flags"), (a = !0), r();
              },
              ti: () => {
                if (e && a && !this.ji) {
                  S.si(this.u, S.Gs.qi);
                  let e = this.wi;
                  (null == e || e < 1e3 * this.pi) && (e = 1e3 * this.pi),
                    this.$i(Math.min(3e5, k(1e3 * this.pi, 3 * e)), t, s, i);
                }
              },
            }))
          : r();
      },
      S.ii.qi,
    );
  }
  Ni() {
    null != this.ji && (clearTimeout(this.ji), (this.ji = null));
  }
  $i(t = 1e3 * this.pi, s, i, e = !1) {
    this.Ni(),
      (this.ji = window.setTimeout(() => {
        this.refreshFeatureFlags(s, i, e);
      }, t)),
      (this.wi = t);
  }
  Si(t) {
    if (!this.yt) return !1;
    if (!t) {
      const t = this.yt.zi();
      if (null == t) return !1;
      let s = !1;
      if (!isNaN(t)) {
        if (-1 === t) return r.info("Feature flag refreshes not allowed"), !1;
        s = new Date().getTime() >= (this.gi || 0) + 1e3 * t;
      }
      if (!s)
        return (
          r.info(`Feature flag refreshes were rate limited to ${t} seconds`), !1
        );
    }
    return this.yt.yi();
  }
  Mi() {
    var t;
    return (
      (null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.Ui)) || null
    );
  }
  _i() {
    var t, s;
    null === (t = this.u) ||
      void 0 === t ||
      t.k(o.C.Ui, null === (s = this.di) || void 0 === s ? void 0 : s.ki());
  }
  Ai() {
    var t;
    const s = null === (t = this.di) || void 0 === t ? void 0 : t.ki(),
      i = this.Mi();
    return null == i || s === i;
  }
  bi() {
    if (!this.u) return;
    const t = {};
    for (const s of this.mi) {
      const i = s.Y();
      t[s.id] = i;
    }
    this.u.k(o.C.Ti, t), this.u.k(o.C.Bi, this.gi), this._i();
  }
}
