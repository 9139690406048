import { REQUEST_ATTEMPT_DEFAULT as Ae } from "../common/constants.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
const S = {
  Gs: { Qa: "d", Bs: "cc", qi: "ff", Cr: "t" },
  ii: {
    Qa: "/data",
    Bs: "/content_cards/sync",
    qi: "/feature_flags/sync",
    Cr: "/template",
  },
  mc: (e) => (null == e ? void 0 : e.j(o.C.EE)),
  Ac: (e) => (null == e ? void 0 : e.j(o.C.lE)),
  Tc: (e, t) => {
    null == e || e.k(o.C.EE, t);
  },
  Rc: (e, t) => {
    null == e || e.k(o.C.lE, t);
  },
  Yh: (e, t) => {
    if (!e || !t) return -1;
    const s = S.mc(e);
    if (null == s) return -1;
    const n = s[t];
    return null == n || isNaN(n) ? -1 : n;
  },
  Jh: (e, t) => {
    let s = Ae;
    if (!e || !t) return s;
    const n = S.Ac(e);
    return null == n ? s : ((s = n[t]), null == s || isNaN(s) ? Ae : s);
  },
  Os: (e, t, s) => {
    if (!e || !t) return;
    let n = S.mc(e);
    null == n && (n = {}), (n[t] = s), S.Tc(e, n);
  },
  Ys: (e, t, s) => {
    if (!e || !t) return;
    let n = S.Ac(e);
    null == n && (n = {}), (n[t] = s), S.Rc(e, n);
  },
  si: (e, t) => {
    if (!e || !t) return;
    const s = S.Jh(e, t);
    S.Ys(e, t, s + 1);
  },
};
export default S;
