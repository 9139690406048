import e from "../managers/braze-instance.js";
import { MUST_BE_IN_APP_MESSAGE_WARNING as sr } from "../common/constants.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import se from "./in-app-message-manager-factory.js";
import { logger as r } from "../../shared-lib/index.js";
import tt from "../triggers/models/trigger-events.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
export function logInAppMessageButtonClick(o, t) {
  var s;
  if (!e.X()) return !1;
  if (!(o instanceof InAppMessageButton))
    return r.error("button must be an InAppMessageButton object"), !1;
  if (!(t instanceof InAppMessage)) return r.error(sr), !1;
  const n = se.m().Oi(o, t);
  if (n.L)
    for (let r = 0; r < n.ge.length; r++)
      et.rr().fe(
        tt.mo,
        [
          t.triggerId,
          null === (s = o.id) || void 0 === s ? void 0 : s.toString(),
        ],
        n.ge[r],
      );
  return n.L;
}
