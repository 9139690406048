import ri from "./trigger-condition.js";
export default class ur {
  constructor(t) {
    (this.zu = t), (this.zu = t);
  }
  Jl(t) {
    return null == this.zu || ri.Ku(t[0], this.zu);
  }
  static fromJson(t) {
    return new ur(t ? t.campaign_id : null);
  }
  Y() {
    return this.zu;
  }
}
