import C, { readResponseHeaders as b } from "../util/net.js";
import y from "../common/base-provider.js";
import e from "../managers/braze-instance.js";
import ContentCards from "./content-cards.js";
import { dateFromUnixTimestamp as l } from "../util/date-utils.js";
import { isURIJavascriptOrData as N } from "../util/url-utils.js";
import {
  newCardFromContentCardsJson as R,
  newCardFromSerializedValue as D,
} from "../Card/util/card-factory.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import T from "../managers/subscription-manager.js";
import s from "../common/event-logger.js";
import S from "../util/request-header-utils.js";
import { randomInclusive as k } from "../util/math.js";
import { MAX_ERROR_RETRIES_CONTENT_CARDS as U } from "../common/constants.js";
import {
  logger as r,
  IndexedDBAdapter as A,
  EventTypes as i,
} from "../../shared-lib/index.js";
export default class v extends y {
  constructor(t, s, i, h, n) {
    super(),
      (this.wt = t),
      (this.u = s),
      (this.yt = i),
      (this.Ft = h),
      (this.qt = n),
      (this.wt = t),
      (this.u = s),
      (this.yt = i),
      (this.Ft = h),
      (this.qt = n),
      (this.Jt = new T()),
      e.$t(this.Jt),
      (this.Bt = 0),
      (this.Ht = 0),
      (this.cards = []),
      this.Kt();
    const o = A.Yt.Qt;
    new A(o, r).Zt(o.ss.ts, (t) => {
      this.es(t);
    }),
      (this.hs = null),
      (this.rs = null),
      (this.ns = null),
      (this.os = null),
      (this.ls = null),
      (this.us = 10),
      (this.cs = 0);
  }
  fs() {
    return this.hs;
  }
  ds(t) {
    this.hs = t;
  }
  ps() {
    return this.rs;
  }
  vs(t) {
    this.rs = t;
  }
  Kt() {
    if (!this.u) return;
    const t = this.u.j(o.C.Cs) || [],
      s = [];
    for (let i = 0; i < t.length; i++) {
      const e = D(t[i]);
      null != e && s.push(e);
    }
    (this.cards = this.ws(this.bs(s, !1))),
      (this.Bt = this.u.j(o.C.gs) || this.Bt),
      (this.Ht = this.u.j(o.C.ys) || this.Ht);
  }
  Ns(t, s = !1, i = 0, e = 0) {
    let h;
    if (s) {
      h = [];
      for (const t of this.cards) t.test && h.push(t);
    } else h = this.cards.slice();
    for (let i = 0; i < t.length; i++) {
      const e = t[i];
      let r = null;
      for (let t = 0; t < this.cards.length; t++)
        if (e.id === this.cards[t].id) {
          r = this.cards[t];
          break;
        }
      if (s) {
        const t = R(e);
        null != r && r.viewed && t && (t.viewed = !0), null != t && h.push(t);
      } else if (null == r) {
        const t = R(e);
        null != t && h.push(t);
      } else {
        if (!r.Ct(e))
          for (let t = 0; t < h.length; t++)
            if (e.id === h[t].id) {
              h.splice(t, 1);
              break;
            }
      }
    }
    (this.cards = this.ws(this.bs(h, s))),
      this.js(),
      (this.Bt = i),
      (this.Ht = e),
      this.u && (this.u.k(o.C.gs, this.Bt), this.u.k(o.C.ys, this.Ht));
  }
  Rs(t) {
    if (this.Ts() && null != t && t.cards) {
      this.u && this.u.k(o.C.Ds, e.Ss());
      const s = t.full_sync;
      s || this.Kt(),
        this.Ns(t.cards, s, t.last_full_sync_at, t.last_card_updated_at),
        this.Jt.Dt(this.ks(!0));
    }
  }
  Us(t) {
    this.u && this.u.k(o.C.As, t);
  }
  Ls(t, e, h) {
    const r = () => {
        this.Fs(e, h, !0);
      },
      n = t ? b(t) : null;
    let l;
    if ((this.xs(), !n || !n["retry-after"])) return void this.Us(0);
    const a = n["retry-after"];
    if (isNaN(a) && !isNaN(Date.parse(a)))
      (l = Date.parse(a) - new Date().getTime()), l < 0 && r();
    else {
      if (isNaN(parseFloat(a.toString()))) {
        const t =
          "Received unexpected value for retry-after header in /sync response";
        return s.q(i.Ms, { e: t + ": " + a }), void this.Us(0);
      }
      l = 1e3 * parseFloat(a.toString());
    }
    this.ns = window.setTimeout(() => {
      r();
    }, l);
    let u = 0;
    this.u && (u = this.u.j(o.C.As)),
      (null == u || isNaN(parseInt(u.toString()))) && (u = 0),
      this.Us(parseInt(u.toString()) + 1);
  }
  es(t) {
    var s;
    if (!this.Ts()) return;
    this.Kt();
    const i = this.cards.slice();
    let e = null;
    e = null === (s = this.wt) || void 0 === s ? void 0 : s.getUserId();
    for (let s = 0; s < t.length; s++)
      if (e === t[s].userId || (null == e && null == t[s].userId)) {
        const e = t[s].card;
        let h = null;
        for (let t = 0; t < this.cards.length; t++)
          if (e.id === this.cards[t].id) {
            h = this.cards[t];
            break;
          }
        if (null == h) {
          const t = R(e);
          null != t && i.push(t);
        } else {
          if (!h.Ct(e))
            for (let t = 0; t < i.length; t++)
              if (e.id === i[t].id) {
                i.splice(t, 1);
                break;
              }
        }
      }
    (this.cards = this.ws(this.bs(i, !1))), this.js(), this.Jt.Dt(this.ks(!0));
  }
  bs(t, s) {
    let i = {},
      e = {},
      h = {};
    this.u &&
      ((i = this.u.j(o.C.v) || {}),
      (e = this.u.j(o.C.H) || {}),
      (h = this.u.j(o.C.A) || {}));
    const r = {},
      n = {},
      l = {};
    for (let s = 0; s < t.length; s++) {
      const o = t[s].id;
      o &&
        (i[o] && ((t[s].clicked = !0), (r[o] = !0)),
        e[o] && ((t[s].viewed = !0), (n[o] = !0)),
        h[o] && ((t[s].dismissed = !0), (l[o] = !0)));
    }
    return (
      s &&
        this.u &&
        (this.u.k(o.C.v, r), this.u.k(o.C.H, n), this.u.k(o.C.A, l)),
      t
    );
  }
  ws(t) {
    const s = [],
      i = new Date();
    let e = {};
    this.u && (e = this.u.j(o.C.A) || {});
    let h = !1;
    for (let n = 0; n < t.length; n++) {
      const o = t[n].url;
      if (!this.Ft && o && N(o)) {
        r.error(
          `Card with url ${o} will not be displayed because Javascript URLs are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable this card.`,
        );
        continue;
      }
      const l = t[n].expiresAt;
      let a = !0;
      if ((null != l && (a = l >= i), (a = a && !t[n].dismissed), a))
        s.push(t[n]);
      else {
        const s = t[n].id;
        s && (e[s] = !0), (h = !0);
      }
    }
    return h && this.u && this.u.k(o.C.A, e), s;
  }
  js() {
    var t;
    const s = [];
    for (let t = 0; t < this.cards.length; t++) s.push(this.cards[t].Y());
    null === (t = this.u) || void 0 === t || t.k(o.C.Cs, s);
  }
  xs() {
    this.ns && (clearTimeout(this.ns), (this.ns = null));
  }
  zs() {
    null != this.os && (clearTimeout(this.os), (this.os = null));
  }
  Es(t = 1e3 * this.us, s, i) {
    this.zs(),
      (this.os = window.setTimeout(() => {
        this.Fs(s, i, !0);
      }, t)),
      (this.ls = t);
  }
  Fs(t, s, i = !1, h = !0) {
    var n, l;
    const a = this.qt,
      u = this.u;
    if (!a || !u) return void ("function" == typeof s && s());
    const c = !i;
    if ((c && (this.xs(), this.Us(0)), !this.Ts()))
      return void (
        null === (n = this.yt) ||
        void 0 === n ||
        n.qs(() => {
          this.Fs(t, s);
        })
      );
    let f = !0;
    if (
      (c &&
        (null === (l = this.yt) || void 0 === l ? void 0 : l.Is()) &&
        (f = this.Js()),
      !f)
    )
      return void r.info("Content card sync being throttled.");
    h && this.zs();
    const d = a.Ps({}, !0);
    u.j(o.C.Ds) !== e.Ss() && this.$s(),
      (d.last_full_sync_at = this.Bt),
      (d.last_card_updated_at = this.Ht);
    const m = a._s(d, S.Gs.Bs, i);
    let p = !1;
    a.Hs(
      d,
      () => {
        if (this.u) {
          const t = new Date().valueOf();
          c && this.u.k(o.C.Ks, t), S.Os(this.u, S.Gs.Bs, t);
        }
        C.Qs({
          url: a.Vs() + "/content_cards/sync",
          data: d,
          headers: m,
          L: (i, e) => {
            if (!a.Ws(d, i, m))
              return (p = !0), void ("function" == typeof s && s());
            a.Xs(),
              this.Ls(e, t, s),
              this.Rs(i),
              (p = !1),
              S.Ys(this.u, S.Gs.Bs, 1),
              "function" == typeof t && t();
          },
          error: (t) => {
            a.Zs(t, "retrieving content cards"),
              (p = !0),
              "function" == typeof s && s();
          },
          ti: () => {
            if (p && h && !this.os && this.cs + 1 < U) {
              S.si(this.u, S.Gs.Bs);
              let i = this.ls;
              (null == i || i < 1e3 * this.us) && (i = 1e3 * this.us),
                this.Es(Math.min(3e5, k(1e3 * this.us, 3 * i)), t, s),
                (this.cs = this.cs + 1);
            }
          },
        });
      },
      S.ii.Bs,
    );
  }
  ks(t) {
    t || this.Kt();
    const s = this.ws(this.cards);
    s.sort((t, s) =>
      t.pinned && !s.pinned
        ? -1
        : s.pinned && !t.pinned
        ? 1
        : t.updated && s.updated && t.updated > s.updated
        ? -1
        : t.updated && s.updated && s.updated > t.updated
        ? 1
        : 0,
    );
    let i = Math.max(this.Ht || 0, this.Bt || 0);
    return (
      0 === i && (i = void 0),
      this.u && this.u.j(o.C.ys) === this.Ht && void 0 === i && (i = this.Ht),
      new ContentCards(s, l(i))
    );
  }
  ei(t) {
    return this.Jt.It(t);
  }
  $s() {
    (this.Bt = 0),
      (this.Ht = 0),
      this.u && (this.u.hi(o.C.gs), this.u.hi(o.C.ys));
  }
  changeUser(t) {
    t ||
      ((this.cards = []),
      this.Jt.Dt(new ContentCards(this.cards.slice(), null)),
      this.u &&
        (this.u.hi(o.C.Cs),
        this.u.hi(o.C.v),
        this.u.hi(o.C.H),
        this.u.hi(o.C.A))),
      this.$s();
  }
  clearData(t) {
    (this.Bt = 0),
      (this.Ht = 0),
      (this.cards = []),
      this.Jt.Dt(new ContentCards(this.cards.slice(), null)),
      t &&
        this.u &&
        (this.u.hi(o.C.Cs),
        this.u.hi(o.C.v),
        this.u.hi(o.C.H),
        this.u.hi(o.C.A),
        this.u.hi(o.C.gs),
        this.u.hi(o.C.ys));
  }
  Ts() {
    return (
      !!this.yt && (!!this.yt.ri() || (0 !== this.yt.ni() && this.oi(), !1))
    );
  }
  li(t) {
    this.u && this.u.k(o.C.ai, t);
  }
  ui() {
    return this.u ? this.u.j(o.C.ai) : null;
  }
  Js() {
    var t, s, i;
    const e = null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.Ks);
    if (null == e || isNaN(e)) return !0;
    const h = (null === (s = this.yt) || void 0 === s ? void 0 : s.ci()) || -1,
      r = (null === (i = this.yt) || void 0 === i ? void 0 : i.fi()) || -1;
    if (-1 === h || -1 === r) return !0;
    let n = this.ui();
    (null == n || isNaN(n)) && (n = h);
    const l = (new Date().valueOf() - e) / 1e3;
    return (
      (n = Math.min(n + l / r, h)),
      !(n < 1) && ((n = Math.trunc(n) - 1), this.li(n), !0)
    );
  }
  oi() {
    this.Jt.Dt(new ContentCards([], new Date())), this.u && this.u.hi(o.C.Cs);
  }
}
