import { isDate as yt } from "../../util/code-utils.js";
import {
  convertMsToSeconds as h,
  dateFromUnixTimestamp as l,
  secondsAgo as es,
  secondsInTheFuture as ls,
} from "../../util/date-utils.js";
export default class lr {
  constructor(t, s, e, i) {
    (this.Tl = t),
      (this.Al = s),
      (this.comparator = e),
      (this._l = i),
      (this.Tl = t),
      (this.Al = s),
      (this.comparator = e),
      (this._l = i),
      this.Al === lr.Il.Ll &&
        this.comparator !== lr.Ul.Ol &&
        this.comparator !== lr.Ul.Ql &&
        this.comparator !== lr.Ul.Xl &&
        this.comparator !== lr.Ul.Fl &&
        (this._l = l(this._l));
  }
  Jl(t) {
    let s = null;
    switch ((null != t && (s = t[this.Tl]), this.comparator)) {
      case lr.Ul.Kl:
        return null != s && s.valueOf() === this._l.valueOf();
      case lr.Ul.Pl:
        return null == s || s.valueOf() !== this._l.valueOf();
      case lr.Ul.Yl:
        return null != s && typeof s == typeof this._l && s > this._l;
      case lr.Ul.Ol:
        return this.Al === lr.Il.Ll
          ? null != s && yt(s) && es(s) <= this._l.valueOf()
          : null != s && typeof s == typeof this._l && s >= this._l;
      case lr.Ul.Zl:
        return null != s && typeof s == typeof this._l && s < this._l;
      case lr.Ul.Ql:
        return this.Al === lr.Il.Ll
          ? null != s && yt(s) && es(s) >= this._l.valueOf()
          : null != s && typeof s == typeof this._l && s <= this._l;
      case lr.Ul.$l:
        return (
          null != s &&
          "string" == typeof s &&
          typeof s == typeof this._l &&
          null != s.match(this._l)
        );
      case lr.Ul.Eu:
        return null != s;
      case lr.Ul.Hu:
        return null == s;
      case lr.Ul.Xl:
        return null != s && yt(s) && ls(s) < this._l;
      case lr.Ul.Fl:
        return null != s && yt(s) && ls(s) > this._l;
      case lr.Ul.Qu:
        return (
          null == s ||
          typeof s != typeof this._l ||
          "string" != typeof s ||
          null == s.match(this._l)
        );
    }
    return !1;
  }
  static fromJson(t) {
    return new lr(
      t.property_key,
      t.property_type,
      t.comparator,
      t.property_value,
    );
  }
  Y() {
    let t = this._l;
    return (
      yt(this._l) && (t = h(t.valueOf())),
      { k: this.Tl, t: this.Al, c: this.comparator, v: t }
    );
  }
  static En(t) {
    return new lr(t.k, t.t, t.c, t.v);
  }
}
(lr.Il = { Xu: "boolean", Bu: "number", Gu: "string", Ll: "date" }),
  (lr.Ul = {
    Kl: 1,
    Pl: 2,
    Yl: 3,
    Ol: 4,
    Zl: 5,
    Ql: 6,
    $l: 10,
    Eu: 11,
    Hu: 12,
    Xl: 15,
    Fl: 16,
    Qu: 17,
  });
