import C from "../util/net.js";
import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessage from "./models/in-app-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import s from "../common/event-logger.js";
import { newInAppMessageFromJson as lt } from "./in-app-message-factory.js";
import { randomInclusive as k } from "../util/math.js";
import t from "../models/request-result.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import T from "../managers/subscription-manager.js";
import pt from "../triggers/models/trigger.js";
import { validateValueIsFromEnum as J } from "../util/code-utils.js";
import { BRAZE_ACTION_URI_REGEX as Z } from "../util/validation-utils.js";
import {
  containsPushPrimerBrazeAction as mt,
  containsUnknownBrazeAction as gt,
  getDecodedBrazeAction as W,
  ineligibleBrazeActionURLErrorMessage as ft,
  INELIGIBLE_BRAZE_ACTION_URL_ERROR_TYPES as dt,
} from "../util/braze-actions.js";
import vt from "../Push/utils/push-utils.js";
import S from "../util/request-header-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { FullScreenMessage, ModalMessage, SlideUpMessage } from "./index.js";
export default class aa {
  constructor(t, s, i, r) {
    (this.qt = t),
      (this.di = s),
      (this.u = i),
      (this.wt = r),
      (this.qt = t),
      (this.di = s),
      (this.u = i),
      (this.wt = r),
      (this._e = new T()),
      e.$t(this._e),
      (this.Ne = 1e3),
      (this.Oe = 6e4),
      (this.Re = null);
  }
  He() {
    return this._e;
  }
  Ke(t) {
    return this._e.It(t);
  }
  Qe() {
    return this.Re;
  }
  Ue(t) {
    this.Re = t;
  }
  Ve(e, r) {
    if (!e) return new t();
    if (
      !J(
        InAppMessage.Me,
        r,
        `${r} is not a valid in-app message display failure`,
        "InAppMessage.DisplayFailures",
      )
    )
      return new t();
    const n = { trigger_ids: [e], error_code: r };
    return s.q(i.We, n);
  }
  q(e, n, o, a) {
    const l = new t();
    let u;
    if (e instanceof ControlMessage) u = { trigger_ids: [e.triggerId] };
    else {
      if (n === i.Xe || (e instanceof HtmlMessage && n === i.Ye)) {
        if (!e.p(a))
          return (
            r.info(
              "This in-app message has already received a click. Ignoring analytics event.",
            ),
            l
          );
      } else if (n === i.Ze) {
        if (!e.K())
          return (
            r.info(
              "This in-app message has already received an impression. Ignoring analytics event.",
            ),
            l
          );
      }
      u = this.Pi(e);
    }
    return null == u ? l : (null != o && (u.bid = o), s.q(n, u));
  }
  Oi(e, n) {
    const o = new t();
    if (!e.p())
      return (
        r.info(
          "This in-app message button has already received a click. Ignoring analytics event.",
        ),
        o
      );
    const a = this.Pi(n);
    return null == a
      ? o
      : e.id === InAppMessageButton.Qi
      ? (r.info(
          "This in-app message button does not have a tracking id. Not logging event to Braze servers.",
        ),
        o)
      : (null != e.id && (a.bid = e.id), s.q(i.Ye, a));
  }
  Vi(t) {
    const e = t.messageFields;
    return (null != e && e.is_push_primer) || !1;
  }
  Wi(t) {
    if (!(t instanceof InAppMessage)) return;
    const e = (t) => {
      if (!t) return;
      const e = W(t);
      return gt(e)
        ? ft(dt.Xi, "In-App Message")
        : mt(e) && !vt.Yi()
        ? ft(dt.Zi, "In-App Message")
        : void 0;
    };
    if (this.Vi(t) && !vt.Yi())
      return "In-App Message contains a push prompt, but is not eligible for a push prompt. Ignoring.";
    const s = t.buttons || [];
    let i;
    for (const t of s)
      if (
        t.clickAction === InAppMessage.ClickAction.URI &&
        t.uri &&
        Z.test(t.uri) &&
        ((i = e(t.uri)), i)
      )
        return i;
    return t.clickAction === InAppMessage.ClickAction.URI &&
      t.uri &&
      Z.test(t.uri)
      ? e(t.uri)
      : void 0;
  }
  Mr(t, e, s, i) {
    if (!this.qt) return;
    const n = this.qt._r(!1, !1),
      o = this.qt.Ps(n);
    (o.template = { trigger_id: t.triggerId, trigger_event_type: e }),
      null != s && (o.template.data = s.Gr());
    const a = this.qt._s(o, S.Gs.Cr);
    this.qt.Hs(
      o,
      () => {
        this.qt &&
          (S.Os(this.u, S.Gs.Cr, new Date().valueOf()),
          C.Qs({
            url: `${this.qt.Vs()}/template/`,
            data: o,
            headers: a,
            L: (e) => {
              if ((S.Ys(this.u, S.Gs.Cr, 1), !this.qt.Ws(o, e, a)))
                return (
                  this.Ve(t.triggerId, InAppMessage.Me.Or),
                  void ("function" == typeof t.Hr && t.Hr())
                );
              if ((this.qt.Xs(), null == e || null == e.templated_message))
                return void this.Ve(t.triggerId, InAppMessage.Me.Or);
              const s = e.templated_message;
              if (s.type !== pt.Kr.Jr)
                return void this.Ve(t.triggerId, InAppMessage.Me.Lr);
              const i = lt(s.data);
              if (null == i)
                return void this.Ve(t.triggerId, InAppMessage.Me.Lr);
              const n = this.Wi(i);
              if (n)
                return r.error(n), void ("function" == typeof t.Hr && t.Hr());
              "function" == typeof t.Qr
                ? t.Qr(i)
                : this.Ve(t.triggerId, InAppMessage.Me.Or);
            },
            error: (r) => {
              let n = `getting user personalization for message ${t.triggerId}`;
              if (new Date().valueOf() - t.Ur > t.Vr)
                this.Ve(t.triggerId, InAppMessage.Me.Or);
              else {
                S.si(this.u, S.Gs.Cr);
                const r = Math.min(t.Vr, this.Oe),
                  o = this.Ne;
                null == i && (i = o);
                const a = Math.min(r, k(o, 3 * i));
                (n += `. Retrying in ${a} ms`),
                  setTimeout(() => {
                    this.Mr(t, e, s, a);
                  }, a);
              }
              this.qt.Zs(r, n);
            },
          }));
      },
      S.ii.Cr,
    );
  }
  Pi(t) {
    if (null == t.triggerId)
      return (
        r.info(
          "The in-app message has no analytics id. Not logging event to Braze servers.",
        ),
        null
      );
    const e = {};
    return null != t.triggerId && (e.trigger_ids = [t.triggerId]), e;
  }
  de(t) {
    return (
      !!this.u &&
      !(
        !(t && t instanceof InAppMessage && t.constructor !== InAppMessage) ||
        t instanceof ControlMessage
      ) &&
      this.u.k(o.C.Wr, t.Y())
    );
  }
  Ge() {
    if (!this.u) return null;
    const t = this.u.j(o.C.Wr);
    if (!t) return null;
    let e;
    switch (t.type) {
      case InAppMessage.Ce.Xr:
        e = FullScreenMessage.Yr(t);
        break;
      case InAppMessage.Ce.Zr:
      case InAppMessage.Ce.je:
      case InAppMessage.Ce.Se:
        e = HtmlMessage.Yr(t);
        break;
      case InAppMessage.Ce.sn:
      case InAppMessage.Ce.Je:
        e = ModalMessage.Yr(t);
        break;
      case InAppMessage.Ce.rn:
        e = SlideUpMessage.Yr(t);
    }
    return e && this.on(), e;
  }
  on() {
    this.u && this.u.hi(o.C.Wr);
  }
}
