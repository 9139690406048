const i = {
    CustomEvent: "ce",
    Pr: "p",
    Iu: "pc",
    vc: "ca",
    Za: "i",
    Ms: "ie",
    M: "cci",
    R: "ccic",
    I: "ccc",
    F: "ccd",
    kl: "ss",
    xl: "se",
    Ze: "si",
    Xe: "sc",
    Ye: "sbc",
    We: "sfe",
    fo: "iec",
    xu: "lr",
    Pu: "uae",
    O: "ci",
    $: "cc",
    Uu: "lcaa",
    Ou: "lcar",
    Hn: "inc",
    On: "add",
    Gn: "rem",
    Ln: "set",
    Rn: "ncam",
    _u: "sgu",
    Fr: "ffi",
  },
  or = { Br: "feed_displayed", Cc: "content_cards_displayed" };
export { i as EventTypes, or as InternalEventTypes };
