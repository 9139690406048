import { logger as r } from "../../shared-lib/index.js";
import e from "../managers/braze-instance.js";
import ir from "./feature-flags-provider-factory.js";
export function getFeatureFlag(t) {
  if (!e.X()) return;
  const n = e.ir();
  if (n && !n.yi()) return r.info("Feature flags are not enabled."), null;
  const a = ir.rr().Ci();
  return a[t] ? a[t] : null;
}
