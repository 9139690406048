import ts from "./filter-set.js";
export default class is {
  constructor(t, s) {
    (this.Bl = t), (this.Ml = s), (this.Bl = t), (this.Ml = s);
  }
  Jl(t) {
    if (null == this.Bl || null == this.Ml) return !1;
    const s = t[0],
      i = t[1];
    return s === this.Bl && this.Ml.Jl(i);
  }
  static fromJson(t) {
    return new is(
      t ? t.event_name : null,
      t ? ts.fromJson(t.property_filters) : null,
    );
  }
  Y() {
    return { e: this.Bl, pf: this.Ml ? this.Ml.Y() : null };
  }
}
