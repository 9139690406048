import ge from "./base-device-parser.js";
import { Browsers as gi } from "./device-constants.js";
export default class ai extends ge {
  constructor() {
    if (
      (super(),
      (this.userAgentData = navigator.userAgentData),
      (this.browser = null),
      (this.version = null),
      this.userAgentData)
    ) {
      const t = this.hc();
      (this.browser = t.browser || "Unknown Browser"),
        (this.version = t.version || "Unknown Version");
    }
    this.OS = null;
  }
  tf() {
    return this.browser;
  }
  ef() {
    return this.version;
  }
  Oa(t) {
    if (this.OS) return Promise.resolve(this.OS);
    const s = (s) => {
      for (let r = 0; r < t.length; r++) {
        const i = ai.rf(s, t[r]);
        if (i) return (this.OS = i), this.OS;
      }
      return s;
    };
    return this.userAgentData.platform
      ? Promise.resolve(s(this.userAgentData.platform))
      : this.getHighEntropyValues()
          .then((t) => (t.platform ? s(t.platform) : navigator.platform))
          .catch(() => navigator.platform);
  }
  hc() {
    const t = {},
      s = this.userAgentData.brands;
    if (s && s.length)
      for (const r of s) {
        const s = this.uc(gi),
          i = r.brand.match(s);
        if (i && i.length > 0) {
          (t.browser = i[0]), (t.version = r.version);
          break;
        }
      }
    return t;
  }
  uc(t) {
    const s = [];
    for (const r in t) {
      const i = r;
      s.push(t[i]);
    }
    return new RegExp("(" + s.join("|") + ")", "i");
  }
  getHighEntropyValues() {
    return this.userAgentData.getHighEntropyValues
      ? this.userAgentData.getHighEntropyValues(["platform"])
      : Promise.reject();
  }
}
