import y from "../common/base-provider.js";
import { newInAppMessageFromJson as lt } from "../InAppMessage/in-app-message-factory.js";
import ue from "../models/braze-event.js";
import { InAppMessage } from "../InAppMessage/index.js";
import {
  isArray as j,
  isEqual as ii,
  validateValueIsFromEnum as J,
} from "../util/code-utils.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import wt from "../InAppMessage/models/templated-in-app-message.js";
import pt from "./models/trigger.js";
import tt from "./models/trigger-events.js";
import { logger as r } from "../../shared-lib/index.js";
export default class gr extends y {
  constructor(t, i, s, e, r) {
    super(),
      (this.tg = t),
      (this.Jt = i),
      (this.u = s),
      (this.Ei = e),
      (this.ig = r),
      (this.tg = t),
      (this.Jt = i),
      (this.u = s),
      (this.Ei = e),
      (this.ig = r),
      (this.sg = []),
      (this.eg = []),
      (this.hg = null),
      (this.ng = {}),
      (this.og = {}),
      (this.triggers = []),
      (this.ag = 0),
      this.lg(),
      this.gg();
  }
  fg() {
    if (this.u) {
      (this.hg = this.u.j(o.C.aE) || this.hg),
        (this.ng = this.u.j(o.C.iE) || this.ng),
        (this.og = this.u.j(o.C.nE) || this.og);
      for (let t = 0; t < this.triggers.length; t++) {
        const i = this.triggers[t];
        i.id && null != this.og[i.id] && i.rd(this.og[i.id]);
      }
    }
  }
  lg() {
    if (!this.u) return;
    this.ag = this.u.j(o.C.oE) || 0;
    const t = this.u.j(o.C.rE) || [],
      i = [];
    for (let s = 0; s < t.length; s++) i.push(pt.En(t[s]));
    (this.triggers = i), this.fg();
  }
  gg() {
    const t = this,
      i = function (i, s, e, r, h) {
        return function () {
          t.cg(i, s, e, r, h);
        };
      },
      s = {};
    for (const t of this.triggers) t.id && (s[t.id] = t);
    let e = !1;
    for (let t = 0; t < this.triggers.length; t++) {
      const r = this.triggers[t];
      if (r.id && null != this.ng[r.id]) {
        const t = this.ng[r.id],
          h = [];
        for (let e = 0; e < t.length; e++) {
          const n = t[e],
            o = r.hd(n.Ur || 0);
          if (o > 0) {
            let t, e;
            h.push(n),
              null != n.ug && (t = n.ug),
              null != n.dg && ue.cE(n.dg) && (e = ue.En(n.dg));
            const a = [];
            if (n.pg && j(n.pg))
              for (let t = 0; t < n.pg.length; t++) {
                const i = s[n.pg[t]];
                null != i && a.push(i);
              }
            this.eg.push(window.setTimeout(i(r, n.Ur || 0, t, e, a), o));
          }
        }
        this.ng[r.id].length > h.length &&
          ((this.ng[r.id] = h),
          (e = !0),
          0 === this.ng[r.id].length && delete this.ng[r.id]);
      }
    }
    e && this.u && this.u.k(o.C.iE, this.ng);
  }
  mg() {
    if (!this.u) return;
    const t = [];
    for (let i = 0; i < this.triggers.length; i++) t.push(this.triggers[i].Y());
    (this.ag = new Date().valueOf()),
      this.u.k(o.C.rE, t),
      this.u.k(o.C.oE, this.ag);
  }
  bg() {
    if (!this.u) return;
    (this.u.j(o.C.oE) || 0) > this.ag ? this.lg() : this.fg();
  }
  Rs(t) {
    let i = !1;
    if (null != t && t.triggers) {
      this.ig.on(), this.fg();
      const s = {},
        e = {};
      this.triggers = [];
      for (let r = 0; r < t.triggers.length; r++) {
        const h = pt.fromJson(t.triggers[r]);
        if (h) {
          h.id &&
            null != this.og[h.id] &&
            (h.rd(this.og[h.id]), (s[h.id] = this.og[h.id])),
            h.id && null != this.ng[h.id] && (e[h.id] = this.ng[h.id]);
          for (let t = 0; t < h.Wu.length; t++)
            if (h.Wu[t].sc(tt.kt, null)) {
              i = !0;
              break;
            }
          this.triggers.push(h);
        }
      }
      ii(this.og, s) || ((this.og = s), this.u && this.u.k(o.C.nE, this.og)),
        ii(this.ng, e) || ((this.ng = e), this.u && this.u.k(o.C.iE, this.ng)),
        this.mg(),
        i &&
          (r.info("Trigger with test condition found, firing test."),
          this.fe(tt.kt)),
        this.fe(tt.OPEN);
      const h = this.sg;
      let n;
      this.sg = [];
      for (let t = 0; t < h.length; t++)
        (n = Array.prototype.slice.call(h[t])), this.fe(...n);
    }
  }
  cg(t, i, s, e, h) {
    const n = (e) => {
        this.fg();
        const h = new Date().valueOf();
        if (!t.od(i))
          return !1 === navigator.onLine && t.type === pt.Kr.Jr && e.imageUrl
            ? (r.info(
                `Not showing ${t.type} trigger action ${t.id} due to offline state.`,
              ),
              void this.ig.Ve(t.id, InAppMessage.Me.Uh))
            : void (t.ed(h) && this.wg(t, h, s)
                ? 0 === this.Jt.ic()
                  ? r.info(
                      `Not displaying trigger ${t.id} because neither automaticallyShowInAppMessages() nor subscribeToInAppMessage() were called.`,
                    )
                  : (this.Jt.Dt([e]), this.yg(t, h))
                : r.info(
                    `Not displaying trigger ${t.id} because display time fell outside of the acceptable time window.`,
                  ));
        t.type === pt.Kr.nd
          ? this.ig.Ve(t.id, InAppMessage.Me.Or)
          : this.ig.Ve(t.id, InAppMessage.Me.bh);
      },
      o = () => {
        this.fg();
        const n = h.pop();
        if (null != n)
          if ((this.Tg(n, i, s, e, h), n.od(i))) {
            let t = `Server aborted in-app message display, but the timeout on fallback trigger ${n.id} has already elapsed.`;
            h.length > 0 && (t += " Continuing to fall back."),
              r.info(t),
              this.ig.Ve(n.id, InAppMessage.Me.bh),
              o();
          } else {
            r.info(
              `Server aborted in-app message display. Falling back to lower priority ${n.type} trigger action ${t.id}.`,
            );
            const o = 1e3 * n.Yu - (new Date().valueOf() - i);
            o > 0
              ? this.eg.push(
                  window.setTimeout(() => {
                    this.cg(n, i, s, e, h);
                  }, o),
                )
              : this.cg(n, i, s, e, h);
          }
      };
    let a, l, g;
    switch (t.type) {
      case pt.Kr.Jr:
        if (((a = lt(t.data)), null == a)) {
          r.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          ),
            this.ig.Ve(t.id, InAppMessage.Me.Lr);
          break;
        }
        if (((l = this.ig.Wi(a)), l)) {
          r.error(l), o();
          break;
        }
        n(a);
        break;
      case pt.Kr.nd:
        if (((g = wt.fromJson(t.data, n, o, i, t.Vr || 0)), null == g)) {
          r.error(
            `Could not parse trigger data for trigger ${t.id}, ignoring.`,
          ),
            this.ig.Ve(t.id, InAppMessage.Me.Lr);
          break;
        }
        this.ig.Mr(g, s, e);
        break;
      default:
        r.error(`Trigger ${t.id} was of unexpected type ${t.type}, ignoring.`),
          this.ig.Ve(t.id, InAppMessage.Me.Lr);
    }
  }
  fe(t, i = null, s) {
    if (!J(tt, t, "Cannot fire trigger action.", "TriggerEvents")) return;
    if (this.Ei && this.Ei.Lu())
      return (
        r.info(
          "Trigger sync is currently in progress, awaiting sync completion before firing trigger event.",
        ),
        void this.sg.push(arguments)
      );
    this.bg();
    const e = new Date().valueOf(),
      h = e - (this.hg || 0);
    let n = !0,
      o = !0;
    const a = [];
    for (let s = 0; s < this.triggers.length; s++) {
      const r = this.triggers[s],
        h = e + 1e3 * r.Yu;
      if (
        r.ed(h) &&
        (null == r.startTime || r.startTime.valueOf() <= e) &&
        (null == r.endTime || r.endTime.valueOf() >= e)
      ) {
        let s = !1;
        for (let e = 0; e < r.Wu.length; e++)
          if (r.Wu[e].sc(t, i)) {
            s = !0;
            break;
          }
        s && ((n = !1), this.wg(r, h, t) && ((o = !1), a.push(r)));
      }
    }
    if (n)
      return void r.info(
        `Trigger event ${t} did not match any trigger conditions.`,
      );
    if (o)
      return void r.info(
        `Ignoring ${t} trigger event because a trigger was displayed ${
          h / 1e3
        }s ago.`,
      );
    a.sort((t, i) => t.priority - i.priority);
    const l = a.pop();
    null != l &&
      (r.info(
        `Firing ${l.type} trigger action ${l.id} from trigger event ${t}.`,
      ),
      this.Tg(l, e, t, s, a),
      0 === l.Yu
        ? this.cg(l, e, t, s, a)
        : this.eg.push(
            window.setTimeout(() => {
              this.cg(l, e, t, s, a);
            }, 1e3 * l.Yu),
          ));
  }
  changeUser(t = !1) {
    if (((this.triggers = []), this.u && this.u.hi(o.C.rE), !t)) {
      (this.sg = []), (this.hg = null), (this.og = {}), (this.ng = {});
      for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
      (this.eg = []),
        this.u && (this.u.hi(o.C.aE), this.u.hi(o.C.nE), this.u.hi(o.C.iE));
    }
  }
  clearData() {
    (this.triggers = []), (this.hg = null), (this.og = {}), (this.ng = {});
    for (let t = 0; t < this.eg.length; t++) clearTimeout(this.eg[t]);
    this.eg = [];
  }
  wg(t, i, s) {
    if (null == this.hg) return !0;
    if (s === tt.kt)
      return (
        r.info(
          "Ignoring minimum interval between trigger because it is a test type.",
        ),
        !0
      );
    let e = t.td;
    return null == e && (e = this.tg), i - this.hg >= 1e3 * e;
  }
  Tg(t, i, s, e, r) {
    this.fg(), t.id && (this.ng[t.id] = this.ng[t.id] || []);
    const h = {};
    let n;
    (h.Ur = i), (h.ug = s), null != e && (n = e.Y()), (h.dg = n);
    const a = [];
    for (const t of r) t.id && a.push(t.id);
    (h.pg = a),
      t.id && this.ng[t.id].push(h),
      this.u && this.u.k(o.C.iE, this.ng);
  }
  yg(t, i) {
    this.fg(),
      t.rd(i),
      (this.hg = i),
      t.id && (this.og[t.id] = i),
      this.u && (this.u.k(o.C.aE, i), this.u.k(o.C.nE, this.og));
  }
}
