export default class ControlMessage {
  constructor(t) {
    (this.triggerId = t),
      (this.triggerId = t),
      (this.extras = {}),
      (this.isControl = !0);
  }
  static fromJson(t) {
    return new ControlMessage(t.trigger_id);
  }
}
