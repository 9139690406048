import { createCloseButton as d } from "../../util/component-utils.js";
import { detectSwipe as c, DIRECTIONS as m } from "../../util/dom-utils.js";
import { _handleBrazeAction as u } from "../../Core/handle-braze-action.js";
import { logCardDismissal } from "../index.js";
import { Guid as p } from "../../../shared-lib/index.js";
export const TOP_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-top-impression";
export const BOTTOM_IMPRESSION_DATA_ATTRIBUTE = "data-ab-had-bottom-impression";
export function topHadImpression(t) {
  return null != t && !!t.getAttribute("data-ab-had-top-impression");
}
export function impressOnTop(t) {
  null != t && t.setAttribute("data-ab-had-top-impression", "true");
}
export function bottomHadImpression(t) {
  return null != t && !!t.getAttribute("data-ab-had-bottom-impression");
}
export function impressOnBottom(t) {
  null != t && t.setAttribute("data-ab-had-bottom-impression", "true");
}
export function markCardAsRead(t) {
  if (null != t) {
    const o = t.querySelectorAll(".ab-unread-indicator")[0];
    null != o && (o.className += " read");
  }
}
export function getCardId(t) {
  return t.getAttribute("data-ab-card-id");
}
export function _setImageAltText(t, o) {
  let e = "";
  t.title || t.description || (e = "Feed Image"), o.setAttribute("alt", e);
}
export function setCardHeight(t, o) {
  const e = o.querySelectorAll(".ab-image-area");
  let a,
    n = 0;
  e.length > 0 && (n = e[0].offsetWidth);
  for (const o of t)
    if (((a = o.T), a && o.imageUrl && "number" == typeof o.aspectRatio)) {
      const t = n / o.aspectRatio;
      t && (a.style.height = `${t}px`);
    }
}
export function cardToHtml(t, logCardClick, o) {
  const e = document.createElement("div");
  (e.className = "ab-card ab-effect-card " + t._),
    t.id &&
      (e.setAttribute("data-ab-card-id", t.id), e.setAttribute("id", t.id)),
    e.setAttribute("role", "article"),
    e.setAttribute("tabindex", "0");
  let a = "",
    n = !1;
  t.url && "" !== t.url && ((a = t.url), (n = !0));
  const i = (i) => (markCardAsRead(e), n && (logCardClick(t), u(a, o, i)), !1);
  if (t.pinned) {
    const t = document.createElement("div");
    t.className = "ab-pinned-indicator";
    const o = document.createElement("i");
    (o.className = "fa fa-star"), t.appendChild(o), e.appendChild(t);
  }
  if (t.imageUrl && "" !== t.imageUrl) {
    const o = document.createElement("div");
    o.className = "ab-image-area";
    const r = document.createElement("img");
    if (
      (r.setAttribute("src", t.imageUrl),
      (r.onload = () => {
        e.style.height = "auto";
      }),
      _setImageAltText(t, r),
      o.appendChild(r),
      (e.className += " with-image"),
      n && !t.S)
    ) {
      const t = document.createElement("a");
      t.setAttribute("href", a),
        (t.onclick = i),
        t.appendChild(o),
        e.appendChild(t);
    } else e.appendChild(o);
  }
  const r = document.createElement("div");
  if (((r.className = "ab-card-body"), t.dismissible)) {
    t.logCardDismissal = () => logCardDismissal(t);
    const o = d("Dismiss Card", void 0, t.dismissCard.bind(t));
    e.appendChild(o),
      c(r, m.U, (t) => {
        (e.className += " ab-swiped-left"), o.onclick(t);
      }),
      c(r, m.V, (t) => {
        (e.className += " ab-swiped-right"), o.onclick(t);
      });
  }
  let s = "",
    l = !1;
  if ((t.title && "" !== t.title && ((s = t.title), (l = !0)), l)) {
    const t = document.createElement("h1");
    if (
      ((t.className = "ab-title"),
      (t.id = p.W()),
      e.setAttribute("aria-labelledby", t.id),
      n)
    ) {
      const o = document.createElement("a");
      o.setAttribute("href", a),
        (o.onclick = i),
        o.appendChild(document.createTextNode(s)),
        t.appendChild(o);
    } else t.appendChild(document.createTextNode(s));
    r.appendChild(t);
  }
  const b = document.createElement("div");
  if (
    ((b.className = l ? "ab-description" : "ab-description ab-no-title"),
    (b.id = p.W()),
    e.setAttribute("aria-describedby", b.id),
    t.description && b.appendChild(document.createTextNode(t.description)),
    n)
  ) {
    const o = document.createElement("div");
    o.className = "ab-url-area";
    const e = document.createElement("a");
    e.setAttribute("href", a),
      t.linkText && e.appendChild(document.createTextNode(t.linkText)),
      (e.onclick = i),
      o.appendChild(e),
      b.appendChild(o);
  }
  r.appendChild(b), e.appendChild(r);
  const f = document.createElement("div");
  return (
    (f.className = "ab-unread-indicator"),
    t.viewed && (f.className += " read"),
    e.appendChild(f),
    (t.T = e),
    e
  );
}
