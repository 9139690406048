import Zt from "./custom-event-data.js";
import is from "./custom-event-property-data.js";
import ts from "./filter-set.js";
import ni from "./in-app-message-click-data.js";
import rs from "./purchase-data.js";
import ns from "./purchase-property-data.js";
import ur from "./push-click-data.js";
import tt from "./trigger-events.js";
import { logger as r } from "../../../shared-lib/index.js";
import { getErrorMessage as si } from "../../util/error-utils.js";
export default class ri {
  constructor(e, t) {
    (this.type = e), (this.data = t), (this.type = e), (this.data = t);
  }
  sc(e, t) {
    return ri.cc[this.type] === e && (null == this.data || this.data.Jl(t));
  }
  static Ku(e, t) {
    let s = null;
    try {
      s = window.atob(e);
    } catch (t) {
      return r.info("Failed to unencode analytics id " + e + ": " + si(t)), !1;
    }
    return t === s.split("_")[0];
  }
  static fromJson(e) {
    const t = e.type;
    let r = null;
    switch (t) {
      case ri.Kr.OPEN:
      case ri.Kr.kt:
        break;
      case ri.Kr.Rr:
        r = rs.fromJson(e.data);
        break;
      case ri.Kr.nc:
        r = ns.fromJson(e.data);
        break;
      case ri.Kr.vr:
        r = ur.fromJson(e.data);
        break;
      case ri.Kr.ue:
        r = Zt.fromJson(e.data);
        break;
      case ri.Kr.lc:
        r = is.fromJson(e.data);
        break;
      case ri.Kr.mo:
        r = ni.fromJson(e.data);
    }
    return new ri(t, r);
  }
  Y() {
    return { t: this.type, d: this.data ? this.data.Y() : null };
  }
  static En(e) {
    let t,
      r = null;
    switch (e.t) {
      case ri.Kr.OPEN:
      case ri.Kr.kt:
        break;
      case ri.Kr.Rr:
        r = new rs(e.d);
        break;
      case ri.Kr.nc:
        (t = e.d || {}), (r = new ns(t.id, ts.En(t.pf || [])));
        break;
      case ri.Kr.vr:
        r = new ur(e.d);
        break;
      case ri.Kr.ue:
        r = new Zt(e.d);
        break;
      case ri.Kr.lc:
        (t = e.d || {}), (r = new is(t.e, ts.En(t.pf || [])));
        break;
      case ri.Kr.mo:
        r = new ni(e.d);
    }
    return new ri(e.t, r);
  }
}
(ri.Kr = {
  OPEN: "open",
  Rr: "purchase",
  nc: "purchase_property",
  vr: "push_click",
  ue: "custom_event",
  lc: "custom_event_property",
  mo: "iam_click",
  kt: "test",
}),
  (ri.cc = {}),
  (ri.cc[ri.Kr.OPEN] = tt.OPEN),
  (ri.cc[ri.Kr.Rr] = tt.Rr),
  (ri.cc[ri.Kr.nc] = tt.Rr),
  (ri.cc[ri.Kr.vr] = tt.vr),
  (ri.cc[ri.Kr.ue] = tt.ue),
  (ri.cc[ri.Kr.lc] = tt.ue),
  (ri.cc[ri.Kr.mo] = tt.mo),
  (ri.cc[ri.Kr.kt] = tt.kt);
