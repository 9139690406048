import se from "../in-app-message-manager-factory.js";
import e from "../../managers/braze-instance.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import { showInAppMessage } from "./show-in-app-message.js";
import { subscribeToInAppMessage } from "../subscribe-to-in-app-message.js";
export function automaticallyShowInAppMessages() {
  if (!e.X()) return;
  ss();
  const s = se.m();
  if (null == s.Qe()) {
    const r = subscribeToInAppMessage((s) => showInAppMessage(s));
    s.Ue(r);
  }
  return s.Qe();
}
