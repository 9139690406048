import InAppMessage from "./models/in-app-message.js";
import ControlMessage from "./models/control-message.js";
import FullScreenMessage from "./models/full-screen-message.js";
import HtmlMessage from "./models/html-message.js";
import InAppMessageButton from "./models/in-app-message-button.js";
import ModalMessage from "./models/modal-message.js";
import SlideUpMessage from "./models/slide-up-message.js";
import { logger as r } from "../../shared-lib/index.js";
export function newInAppMessageFromJson(e) {
  if (!e) return null;
  if (e.is_control) return ControlMessage.fromJson(e);
  let o = e.type;
  null != o && (o = o.toUpperCase());
  const s = e.message,
    n = e.text_align_message,
    m = e.slide_from,
    t = e.extras,
    l = e.trigger_id,
    i = e.click_action,
    f = e.uri,
    p = e.open_target,
    u = e.message_close,
    a = e.duration,
    d = e.icon,
    g = e.image_url,
    c = e.image_style,
    j = e.icon_color,
    w = e.icon_bg_color,
    b = e.bg_color,
    h = e.text_color,
    v = e.close_btn_color,
    x = e.header,
    I = e.text_align_header,
    A = e.header_text_color,
    F = e.frame_color,
    M = [];
  let k = e.btns;
  null == k && (k = []);
  for (let e = 0; e < k.length; e++) M.push(InAppMessageButton.fromJson(k[e]));
  const y = e.crop_type,
    z = e.orientation,
    J = e.animate_in,
    S = e.animate_out;
  let q,
    B = e.html_id,
    C = e.css;
  if (
    ((null != B && "" !== B && null != C && "" !== C) ||
      ((B = void 0), (C = void 0)),
    o === ModalMessage.it || o === InAppMessage.Ce.Je)
  )
    q = new ModalMessage(
      s,
      n,
      t,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      c,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      x,
      I,
      A,
      F,
      M,
      y,
      B,
      C,
    );
  else if (o === FullScreenMessage.it)
    q = new FullScreenMessage(
      s,
      n,
      t,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      c,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      x,
      I,
      A,
      F,
      M,
      y,
      z,
      B,
      C,
    );
  else if (o === SlideUpMessage.it)
    q = new SlideUpMessage(
      s,
      n,
      m,
      t,
      l,
      i,
      f,
      p,
      u,
      a,
      d,
      g,
      j,
      w,
      b,
      h,
      v,
      J,
      S,
      B,
      C,
    );
  else {
    if (
      o !== HtmlMessage.it &&
      o !== InAppMessage.Ce.je &&
      o !== InAppMessage.Ce.Se
    )
      return void r.error("Ignoring message with unknown type " + o);
    {
      const o = e.message_fields;
      (q = new HtmlMessage(s, t, l, u, a, J, S, F, B, C, o)),
        (q.trusted = e.trusted || !1);
    }
  }
  return (q.Ae = o), q;
}
export function buttonsFromSerializedInAppMessage(e) {
  const o = [];
  for (const s of e)
    o.push(
      new InAppMessageButton(
        s.text,
        s.backgroundColor,
        s.textColor,
        s.borderColor,
        s.clickAction,
        s.uri,
        s.id,
      ),
    );
  return o;
}
