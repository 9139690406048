import { addPassiveEventListener as oe, buildSvg as ae } from "./dom-utils.js";
import { KeyCodes as at } from "./key-codes.js";
export function createCloseButton(t, o, e) {
  const n = document.createElement("button");
  n.setAttribute("aria-label", t),
    n.setAttribute("tabindex", "0"),
    n.setAttribute("role", "button"),
    oe(n, "touchstart"),
    (n.className = "ab-close-button");
  const r = ae(
    "0 0 15 15",
    "M15 1.5L13.5 0l-6 6-6-6L0 1.5l6 6-6 6L1.5 15l6-6 6 6 1.5-1.5-6-6 6-6z",
    o,
  );
  return (
    n.appendChild(r),
    n.addEventListener("keydown", (t) => {
      (t.keyCode !== at.To && t.keyCode !== at.Eo) ||
        (e(), t.stopPropagation());
    }),
    (n.onclick = (t) => {
      e(), t.stopPropagation();
    }),
    n
  );
}
