import ue from "../models/braze-event.js";
import _t from "../models/identifier.js";
import {
  isArray as j,
  keys as to,
  validateValueIsFromEnum as J,
  values as Pt,
} from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { User } from "../User/index.js";
import X from "../util/browser-detector.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export const STORAGE_KEYS = {
  iu: {
    su: "ab.storage.userId",
    Ta: "ab.storage.deviceId",
    Dl: "ab.storage.sessionId",
  },
  C: {
    ec: "ab.test",
    tE: "ab.storage.events",
    eE: "ab.storage.attributes",
    sE: "ab.storage.attributes.anonymous_user",
    Ja: "ab.storage.device",
    Na: "ab.storage.sdk_metadata",
    $a: "ab.storage.session_id_for_cached_metadata",
    zn: "ab.storage.pushToken",
    Hi: "ab.storage.newsFeed",
    Ji: "ab.storage.lastNewsFeedRefresh",
    J: "ab.storage.cardImpressions",
    hl: "ab.storage.serverConfig",
    rE: "ab.storage.triggers",
    oE: "ab.storage.triggers.ts",
    Gl: "ab.storage.messagingSessionStart",
    Cs: "ab.storage.cc",
    gs: "ab.storage.ccLastFullSync",
    ys: "ab.storage.ccLastCardUpdated",
    Ks: "ab.storage.ccLastClientRequest",
    ai: "ab.storage.ccRateLimitCurrentTokenCount",
    Ru: "ab.storage.globalRateLimitCurrentTokenCount",
    v: "ab.storage.ccClicks",
    H: "ab.storage.ccImpressions",
    A: "ab.storage.ccDismissals",
    nE: "ab.storage.lastDisplayedTriggerTimesById",
    aE: "ab.storage.lastDisplayedTriggerTime",
    iE: "ab.storage.triggerFireInstancesById",
    jh: "ab.storage.signature",
    As: "ab.storage.brazeSyncRetryCount",
    Ds: "ab.storage.sdkVersion",
    Ti: "ab.storage.ff",
    Ii: "ab.storage.ffImpressions",
    Bi: "ab.storage.ffLastRefreshAt",
    Ui: "ab.storage.ff.sessionId",
    EE: "ab.storage.lastReqToEndpoint",
    lE: "ab.storage.requestAttempts",
    Wr: "ab.storage.deferredIam",
    fu: "ab.storage.lastSdkReq",
  },
  se: "ab.optOut",
};
export default class Q {
  constructor(t, e) {
    (this.SE = t), (this._E = e), (this.SE = t), (this._E = e);
  }
  Vh(t) {
    const e = to(STORAGE_KEYS.iu),
      s = new Q.ee(t);
    for (const t of e) s.remove(STORAGE_KEYS.iu[t]);
  }
  uu(t, e) {
    let s = null;
    null != e && e instanceof _t && (s = e.Y()), this.SE.store(t, s);
  }
  uE(t) {
    const e = this.tu(t);
    null != e && ((e.Hl = new Date().valueOf()), this.uu(t, e));
  }
  tu(t) {
    const e = this.SE.br(t),
      s = ((t) => {
        let e;
        try {
          e = JSON.parse(t);
        } catch (t) {
          e = null;
        }
        return e;
      })(e);
    let r;
    return (
      s ? ((r = _t.En(s) || null), r && this.uu(t, r)) : (r = _t.En(e) || null),
      r
    );
  }
  yl(t) {
    this.SE.remove(t);
  }
  Kh() {
    const t = to(STORAGE_KEYS.iu);
    let e;
    for (const s of t)
      (e = this.tu(STORAGE_KEYS.iu[s])),
        null != e && this.uu(STORAGE_KEYS.iu[s], e);
  }
  zo(t) {
    let e;
    if (null == t || 0 === t.length) return !1;
    e = j(t) ? t : [t];
    let s = this._E.br(STORAGE_KEYS.C.tE);
    (null != s && j(s)) || (s = []);
    for (let t = 0; t < e.length; t++) s.push(e[t].Y());
    return this._E.store(STORAGE_KEYS.C.tE, s);
  }
  Wl(t) {
    return null != t && this.zo([t]);
  }
  TE() {
    let t = this._E.br(STORAGE_KEYS.C.tE);
    this._E.remove(STORAGE_KEYS.C.tE), null == t && (t = []);
    const e = [];
    let s = !1,
      r = null;
    if (j(t))
      for (let s = 0; s < t.length; s++)
        ue.cE(t[s]) ? e.push(ue.En(t[s])) : (r = s);
    else s = !0;
    if (s || null != r) {
      let o = "Stored events could not be deserialized as Events";
      s &&
        (o += ", was " + Object.prototype.toString.call(t) + " not an array"),
        null != r &&
          (o += ", value at index " + r + " does not look like an event"),
        (o +=
          ", serialized values were of type " +
          typeof t +
          ": " +
          JSON.stringify(t)),
        e.push(new ue(null, i.Ms, new Date().valueOf(), null, { e: o }));
    }
    return e;
  }
  k(t, e) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot store object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this._E.store(t, e)
    );
  }
  j(t) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot retrieve object.",
        "STORAGE_KEYS.OBJECTS",
      ) && this._E.br(t)
    );
  }
  hi(t) {
    return (
      !!J(
        STORAGE_KEYS.C,
        t,
        "StorageManager cannot remove object.",
        "STORAGE_KEYS.OBJECTS",
      ) && (this._E.remove(t), !0)
    );
  }
  clearData() {
    const t = to(STORAGE_KEYS.iu),
      e = to(STORAGE_KEYS.C);
    for (let e = 0; e < t.length; e++) {
      const s = t[e];
      this.SE.remove(STORAGE_KEYS.iu[s]);
    }
    for (let t = 0; t < e.length; t++) {
      const s = e[t];
      this._E.remove(STORAGE_KEYS.C[s]);
    }
  }
  hE(t) {
    return t || STORAGE_KEYS.C.sE;
  }
  Va(t) {
    let e = this._E.br(STORAGE_KEYS.C.eE);
    null == e && (e = {});
    const s = this.hE(t[User.Qn]),
      r = e[s];
    for (const o in t)
      o !== User.Qn &&
        (null == e[s] || (r && null == r[o])) &&
        this.mu(t[User.Qn], o, t[o]);
  }
  mu(t, e, s) {
    let r = this._E.br(STORAGE_KEYS.C.eE);
    null == r && (r = {});
    const o = this.hE(t);
    let n = r[o];
    if (
      (null == n && ((n = {}), null != t && (n[User.Qn] = t)), e === User.lu)
    ) {
      null == n[e] && (n[e] = {});
      for (const t in s) n[e][t] = s[t];
    } else n[e] = s;
    return (r[o] = n), this._E.store(STORAGE_KEYS.C.eE, r);
  }
  AE() {
    const t = this._E.br(STORAGE_KEYS.C.eE);
    this._E.remove(STORAGE_KEYS.C.eE);
    const e = [];
    for (const s in t) null != t[s] && e.push(t[s]);
    return e;
  }
  ru(t) {
    const e = this._E.br(STORAGE_KEYS.C.eE);
    if (null != e) {
      const s = this.hE(null),
        r = e[s];
      null != r &&
        ((e[s] = void 0),
        this._E.store(STORAGE_KEYS.C.eE, e),
        (r[User.Qn] = t),
        this.Va(r));
    }
    const s = this.tu(STORAGE_KEYS.iu.Dl);
    let r = null;
    null != s && (r = s.eu);
    const o = this.TE();
    if (null != o)
      for (let e = 0; e < o.length; e++) {
        const s = o[e];
        null == s.userId && s.sessionId == r && (s.userId = t), this.Wl(s);
      }
  }
  RE() {
    return this._E.gE;
  }
}
(Q.rc = class {
  constructor(t) {
    (this.an = t), (this.an = t), (this.gE = X.OE() ? 3 : 10);
  }
  dE(t) {
    return t + "." + this.an;
  }
  store(t, e) {
    const s = { v: e };
    try {
      return localStorage.setItem(this.dE(t), JSON.stringify(s)), !0;
    } catch (t) {
      return r.info("Storage failure: " + si(t)), !1;
    }
  }
  br(t) {
    try {
      let e = null;
      const s = localStorage.getItem(this.dE(t));
      return null != s && (e = JSON.parse(s)), null == e ? null : e.v;
    } catch (t) {
      return r.info("Storage retrieval failure: " + si(t)), null;
    }
  }
  remove(t) {
    try {
      localStorage.removeItem(this.dE(t));
    } catch (t) {
      return r.info("Storage removal failure: " + si(t)), !1;
    }
  }
}),
  (Q.ac = class {
    constructor() {
      (this.fE = {}), (this.IE = 5242880), (this.gE = 3);
    }
    store(t, e) {
      const s = { value: e },
        o = this.bE(e);
      return o > this.IE
        ? (r.info(
            "Storage failure: object is ≈" +
              o +
              " bytes which is greater than the max of " +
              this.IE,
          ),
          !1)
        : ((this.fE[t] = s), !0);
    }
    bE(t) {
      const e = [],
        s = [t];
      let r = 0;
      for (; s.length; ) {
        const t = s.pop();
        if ("boolean" == typeof t) r += 4;
        else if ("string" == typeof t) r += 2 * t.length;
        else if ("number" == typeof t) r += 8;
        else if ("object" == typeof t && -1 === e.indexOf(t)) {
          let r, o;
          e.push(t);
          for (const e in t) (o = t), (r = e), s.push(o[r]);
        }
      }
      return r;
    }
    br(t) {
      const e = this.fE[t];
      return null == e ? null : e.value;
    }
    remove(t) {
      this.fE[t] = null;
    }
  }),
  (Q.ee = class {
    constructor(t, e) {
      (this.an = t),
        (this.KE = e),
        (this.an = t),
        (this.mE = this.YE()),
        (this.NE = 576e3),
        (this.KE = !!e);
    }
    dE(t) {
      return null != this.an ? t + "." + this.an : t;
    }
    YE() {
      let t = 0,
        e = document.location.hostname;
      const s = e.split("."),
        r = "ab._gd";
      for (; t < s.length - 1 && -1 === document.cookie.indexOf(r + "=" + r); )
        t++,
          (e = "." + s.slice(-1 - t).join(".")),
          (document.cookie = r + "=" + r + ";domain=" + e + ";");
      return (
        (document.cookie =
          r + "=;expires=" + new Date(0).toUTCString() + ";domain=" + e + ";"),
        e
      );
    }
    ne() {
      const t = new Date();
      return t.setTime(t.getTime() + 60 * this.NE * 1e3), t.getFullYear();
    }
    CE() {
      const t = Pt(STORAGE_KEYS.iu),
        e = document.cookie.split(";");
      for (let s = 0; s < e.length; s++) {
        let r = e[s];
        for (; " " === r.charAt(0); ) r = r.substring(1);
        let o = !1;
        for (let e = 0; e < t.length; e++)
          if (0 === r.indexOf(t[e])) {
            o = !0;
            break;
          }
        if (o) {
          const t = r.split("=")[0];
          -1 === t.indexOf("." + this.an) && this.GE(t);
        }
      }
    }
    store(t, e) {
      this.CE();
      const s = new Date();
      s.setTime(s.getTime() + 60 * this.NE * 1e3);
      const o = "expires=" + s.toUTCString(),
        n = "domain=" + this.mE;
      let a;
      a = this.KE ? e : encodeURIComponent(e);
      const i = this.dE(t) + "=" + a + ";" + o + ";" + n + ";path=/";
      return i.length >= 4093
        ? (r.info(
            "Storage failure: string is " +
              i.length +
              " chars which is too large to store as a cookie.",
          ),
          !1)
        : ((document.cookie = i), !0);
    }
    br(t) {
      const e = [],
        s = this.dE(t) + "=",
        o = document.cookie.split(";");
      for (let n = 0; n < o.length; n++) {
        let a = o[n];
        for (; " " === a.charAt(0); ) a = a.substring(1);
        if (0 === a.indexOf(s))
          try {
            let t;
            (t = this.KE
              ? a.substring(s.length, a.length)
              : decodeURIComponent(a.substring(s.length, a.length))),
              e.push(t);
          } catch (e) {
            return (
              r.info("Storage retrieval failure: " + si(e)),
              this.remove(t),
              null
            );
          }
      }
      return e.length > 0 ? e[e.length - 1] : null;
    }
    remove(t) {
      this.GE(this.dE(t));
    }
    GE(t) {
      const e = t + "=;expires=" + new Date(0).toUTCString();
      (document.cookie = e), (document.cookie = e + ";path=/");
      const s = e + ";domain=" + this.mE;
      (document.cookie = s), (document.cookie = s + ";path=/");
    }
  }),
  (Q.tc = class {
    constructor(t, e, s) {
      (this.an = t),
        (this.DE = []),
        e && this.DE.push(new Q.ee(t)),
        s && this.DE.push(new Q.rc(t)),
        this.DE.push(new Q.ac());
    }
    store(t, e) {
      let s = !0;
      for (let r = 0; r < this.DE.length; r++) s = this.DE[r].store(t, e) && s;
      return s;
    }
    br(t) {
      for (let e = 0; e < this.DE.length; e++) {
        const s = this.DE[e].br(t);
        if (null != s) return s;
      }
      return null;
    }
    remove(t) {
      new Q.ee(this.an).remove(t);
      for (let e = 0; e < this.DE.length; e++) this.DE[e].remove(t);
    }
  });
