import X from "../../util/browser-detector.js";
import { logInAppMessageHtmlClick } from "../log-in-app-message-html-click.js";
import { getUser as ro } from "../../Core/get-user.js";
import { InAppMessage } from "../index.js";
import { keys as to } from "../../util/code-utils.js";
import { OperatingSystems as Y } from "../../util/device-constants.js";
import { parseQueryStringKeyValues as ut } from "../../util/url-utils.js";
import { WindowUtils as eo } from "../../util/window-utils.js";
import e from "../../managers/braze-instance.js";
import { logger as r } from "../../../shared-lib/index.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../../common/constants.js";
export default function ct(t, o, n, s, i, u) {
  const c = document.createElement("iframe");
  c.setAttribute("title", "Modal Message"),
    i && (c.style.zIndex = (i + 1).toString());
  const a = (e) => {
      const o = e.getAttribute("href"),
        n = e.onclick;
      return (r) => {
        if (null != n && "function" == typeof n && !1 === n.bind(e)(r)) return;
        let i = ut(o).abButtonId;
        if (
          ((null != i && "" !== i) || (i = e.getAttribute("id") || void 0),
          null != o && "" !== o && 0 !== o.indexOf("#"))
        ) {
          const n =
              "blank" ===
              (e.getAttribute("target") || "").toLowerCase().replace("_", ""),
            u = s || t.openTarget === InAppMessage.OpenTarget.BLANK || n,
            a = () => {
              logInAppMessageHtmlClick(t, i, o), eo.openUri(o, u, r);
            };
          u ? a() : t.he(c, a);
        } else logInAppMessageHtmlClick(t, i, o || void 0);
        return r.stopPropagation(), !1;
      };
    },
    m = (t, e, o) => {
      const n = `([\\w]+)\\s*=\\s*document.createElement\\(['"]${o}['"]\\)`,
        r = t.match(new RegExp(n));
      if (r) {
        const o = `${r[1]}.setAttribute("nonce", "${e}")`;
        return `${t.slice(0, r.index + r[0].length)};${o};${t.slice(
          r.index + r[0].length,
        )}`;
      }
      return null;
    };
  if (
    ((c.onload = () => {
      let s = null;
      if (null != u) {
        (s = document.createElement("html")), (s.innerHTML = t.message || "");
        const e = s.getElementsByTagName("style");
        for (let t = 0; t < e.length; t++) e[t].setAttribute("nonce", u);
        const o = s.getElementsByTagName("script");
        for (let t = 0; t < o.length; t++) {
          o[t].setAttribute("nonce", u),
            (o[t].innerHTML = o[t].innerHTML.replace(
              /<style>/g,
              `<style nonce='${u}'>`,
            ));
          const e = m(o[t].innerHTML, u, "script");
          e && (o[t].innerHTML = e);
          const n = m(o[t].innerHTML, u, "style");
          n && (o[t].innerHTML = n);
        }
      }
      const i = c.contentWindow;
      i.focus(), i.document.write(s ? s.innerHTML : t.message || "");
      const l = i.document.getElementsByTagName("head")[0];
      if (null != l) {
        if (t.be()) {
          const e = document.createElement("style");
          (e.innerHTML = t.css || ""),
            (e.id = t.ye() || ""),
            null != u && e.setAttribute("nonce", u),
            l.appendChild(e);
        }
        const e = i.document.createElement("base");
        null != e && (e.setAttribute("target", "_parent"), l.appendChild(e));
      }
      const f = i.document.getElementsByTagName("title");
      f && f.length > 0 && c.setAttribute("title", f[0].textContent || "");
      const d = {
          closeMessage: function () {
            t.he(c);
          },
          logClick: function () {
            logInAppMessageHtmlClick(t, ...arguments);
          },
          display: {},
          web: {},
        },
        requestPushPermission = function () {
          return function () {
            const t = arguments;
            import("../../Push/request-push-permission.js").then((o) => {
              e.so()
                ? o.requestPushPermission(...Array.prototype.slice.call(t))
                : r.error(_);
            });
          };
        },
        p = {
          requestImmediateDataFlush: function () {
            const t = arguments;
            import("../../Core/request-immediate-data-flush.js").then(
              ({ requestImmediateDataFlush: requestImmediateDataFlush }) => {
                e.so()
                  ? requestImmediateDataFlush(...Array.prototype.slice.call(t))
                  : r.error(_);
              },
            );
          },
          logCustomEvent: function () {
            const t = arguments;
            import("../../Core/log-custom-event.js").then(
              ({ logCustomEvent: logCustomEvent }) => {
                if (!e.so()) return void r.error(_);
                logCustomEvent(...Array.prototype.slice.call(t));
              },
            );
          },
          logPurchase: function () {
            const t = arguments;
            import("../../Core/log-purchase.js").then(
              ({ logPurchase: logPurchase }) => {
                if (!e.so()) return void r.error(_);
                logPurchase(...Array.prototype.slice.call(t));
              },
            );
          },
          unregisterPush: function () {
            const t = arguments;
            import("../../Push/unregister-push.js").then(
              ({ unregisterPush: unregisterPush }) => {
                e.so()
                  ? unregisterPush(...Array.prototype.slice.call(t))
                  : r.error(_);
              },
            );
          },
          requestPushPermission: requestPushPermission(),
          changeUser: function () {
            const t = arguments;
            import("../../Core/change-user.js").then(
              ({ changeUser: changeUser }) => {
                if (!e.so()) return void r.error(_);
                changeUser(...Array.prototype.slice.call(t));
              },
            );
          },
        },
        g = function (t) {
          return function () {
            p[t](...Array.prototype.slice.call(arguments));
          };
        };
      for (const t of to(p)) d[t] = g(t);
      const h = [
          "setFirstName",
          "setLastName",
          "setEmail",
          "setGender",
          "setDateOfBirth",
          "setCountry",
          "setHomeCity",
          "setEmailNotificationSubscriptionType",
          "setLanguage",
          "addAlias",
          "setPushNotificationSubscriptionType",
          "setPhoneNumber",
          "setCustomUserAttribute",
          "addToCustomAttributeArray",
          "removeFromCustomAttributeArray",
          "incrementCustomUserAttribute",
          "setCustomLocationAttribute",
          "addToSubscriptionGroup",
          "removeFromSubscriptionGroup",
        ],
        b = function (t) {
          return function () {
            const e = ro();
            e && e[t](...Array.prototype.slice.call(arguments));
          };
        },
        y = {};
      for (let t = 0; t < h.length; t++) y[h[t]] = b(h[t]);
      d.getUser = function () {
        return y;
      };
      const A = { showFeed: o },
        j = function (e) {
          return function () {
            const o = arguments;
            t.he(c, function () {
              A[e](...Array.prototype.slice.call(o));
            });
          };
        },
        C = d.display;
      for (const t of to(A)) C[t] = j(t);
      const v = { registerAppboyPushMessages: requestPushPermission() },
        P = function (t) {
          return function () {
            v[t](...Array.prototype.slice.call(arguments));
          };
        },
        w = d.web;
      for (const t of to(v)) w[t] = P(t);
      if (
        ((i.appboyBridge = d), (i.brazeBridge = d), t.Ae !== InAppMessage.Ce.je)
      ) {
        const t = i.document.getElementsByTagName("a");
        for (let e = 0; e < t.length; e++) t[e].onclick = a(t[e]);
        const e = i.document.getElementsByTagName("button");
        for (let t = 0; t < e.length; t++) e[t].onclick = a(e[t]);
      }
      const E = i.document.body;
      if (null != E) {
        t.ve() && (E.id = t.htmlId || "");
        const e = document.createElement("hidden");
        (e.onclick = d.closeMessage),
          (e.className = "ab-programmatic-close-button"),
          E.appendChild(e);
      }
      i.dispatchEvent(new CustomEvent("ab.BridgeReady")),
        -1 !== c.className.indexOf("ab-start-hidden") &&
          ((c.className = c.className.replace("ab-start-hidden", "")), n(c)),
        document.activeElement !== c && c.focus();
    }),
    (c.className =
      "ab-in-app-message ab-start-hidden ab-html-message ab-modal-interactions"),
    X.OS === Y.io)
  ) {
    const e = document.createElement("div");
    return (
      (e.className = "ab-ios-scroll-wrapper"), e.appendChild(c), (t.Pe = e), e
    );
  }
  return (t.Pe = c), c;
}
