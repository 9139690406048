const r = {
  init: function (n) {
    (void 0 === n && void 0 !== r.zg) || (r.zg = !!n), r.t || (r.t = !0);
  },
  destroy: function () {
    (r.t = !1), (r.zg = void 0), (r.dd = void 0);
  },
  setLogger: function (n) {
    "function" == typeof n
      ? (r.init(), (r.dd = n))
      : r.info("Ignoring setLogger call since logger is not a function");
  },
  toggleLogging: function () {
    r.init(),
      r.zg
        ? (console.log("Disabling Braze logging"), (r.zg = !1))
        : (console.log("Enabled Braze logging"), (r.zg = !0));
  },
  info: function (n) {
    if (r.zg) {
      const o = "Braze: " + n;
      null != r.dd ? r.dd(o) : console.log(o);
    }
  },
  warn: function (n) {
    if (r.zg) {
      const o = "Braze SDK Warning: " + n + " (v5.1.1)";
      null != r.dd ? r.dd(o) : console.warn(o);
    }
  },
  error: function (n) {
    if (r.zg) {
      const o = "Braze SDK Error: " + n + " (v5.1.1)";
      null != r.dd ? r.dd(o) : console.error(o);
    }
  },
};
export default r;
