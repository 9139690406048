export default {
  _o: "allowCrawlerActivity",
  Io: "baseUrl",
  Ao: "noCookies",
  So: "devicePropertyAllowlist",
  qa: "disablePushTokenMaintenance",
  No: "enableLogging",
  Oo: "enableSdkAuthentication",
  ka: "manageServiceWorkerExternally",
  Po: "minimumIntervalBetweenTriggerActionsInSeconds",
  Ro: "sessionTimeoutInSeconds",
  Lo: "appVersion",
  Do: "appVersionNumber",
  Ma: "serviceWorkerLocation",
  _a: "safariWebsitePushId",
  Xn: "localization",
  wo: "contentSecurityNonce",
  Mo: "allowUserSuppliedJavascript",
  ho: "inAppMessageZIndex",
  yo: "openInAppMessagesInNewTab",
  en: "openNewsFeedCardsInNewTab",
  mh: "requireExplicitInAppMessageDismissal",
  Uo: "doNotLoadFontAwesome",
  Bo: "sdkFlavor",
  tn: "openCardsInNewTab",
};
