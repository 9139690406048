import e, { OPTIONS as L } from "../../managers/braze-instance.js";
import ControlMessage from "../models/control-message.js";
import HtmlMessage from "../models/html-message.js";
import InAppMessage from "../models/in-app-message.js";
import SlideUpMessage from "../models/slide-up-message.js";
import se from "../in-app-message-manager-factory.js";
import me from "../display/get-animation-effect.js";
import ce from "../display/in-app-message-to-html.js";
import { logInAppMessageImpression } from "../log-in-app-message-impression.js";
import {
  ORIENTATION as fe,
  WindowUtils as eo,
} from "../../util/window-utils.js";
import { isURIJavascriptOrData as N } from "../../util/url-utils.js";
import { KeyCodes as at } from "../../util/key-codes.js";
import { setupInAppMessageUI as ss } from "../../ui/js/index.js";
import { logger as r } from "../../../shared-lib/index.js";
import { toRgba as ie } from "../../util/color-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../../common/constants.js";
import { isIFrame as It } from "../utils/in-app-message-utils.js";
export function showInAppMessage(t, s, o) {
  if (!e.X()) return;
  if ((ss(), null == t)) return !1;
  if (t instanceof ControlMessage)
    return (
      r.info(
        "User received control for a multivariate test, logging to Braze servers.",
      ),
      logInAppMessageImpression(t),
      !0
    );
  if (!(t instanceof InAppMessage)) return !1;
  if (t.constructor === InAppMessage) return !1;
  const i = se.m();
  t.Nh();
  const n = t instanceof HtmlMessage;
  if (n && !t.trusted && !e.tr())
    return (
      r.error(
        'HTML in-app messages are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these messages.',
      ),
      i.Ve(t.triggerId, InAppMessage.Me.Ch),
      !1
    );
  if ((null == s && (s = document.body), t.xe())) {
    if (s.querySelectorAll(".ab-modal-interactions").length > 0)
      return (
        r.info(
          `Cannot show in-app message ${t.message} because another message is being shown.`,
        ),
        i.Ve(t.triggerId, InAppMessage.Me.ke),
        !1
      );
  }
  if (eo.jo()) {
    const e = eo.bo();
    if (
      (e === fe.PORTRAIT &&
        t.orientation === InAppMessage.Orientation.LANDSCAPE) ||
      (e === fe.LANDSCAPE &&
        t.orientation === InAppMessage.Orientation.PORTRAIT)
    ) {
      const s = e === fe.PORTRAIT ? "portrait" : "landscape",
        o =
          t.orientation === InAppMessage.Orientation.PORTRAIT
            ? "portrait"
            : "landscape";
      return (
        r.info(
          `Not showing ${o} in-app message ${t.message} because the screen is currently ${s}`,
        ),
        i.Ve(t.triggerId, InAppMessage.Me.dh),
        !1
      );
    }
  }
  if (!e.tr()) {
    let e = !1;
    if (t.buttons && t.buttons.length > 0) {
      const s = t.buttons;
      for (let t = 0; t < s.length; t++)
        if (s[t].clickAction === InAppMessage.ClickAction.URI) {
          const o = s[t].uri;
          e = N(o);
        }
    } else t.clickAction === InAppMessage.ClickAction.URI && (e = N(t.uri));
    if (e)
      return (
        r.error(
          'Javascript click actions are disabled. Use the "allowUserSuppliedJavascript" option for braze.initialize to enable these actions.',
        ),
        i.Ve(t.triggerId, InAppMessage.Me.Ch),
        !1
      );
  }
  const a = document.createElement("div");
  if (
    ((a.className = "ab-iam-root v3"),
    (a.className += me(t)),
    a.setAttribute("role", "complementary"),
    t.ve() && (a.id = t.htmlId),
    e.nn(L.ho) && (a.style.zIndex = (e.nn(L.ho) + 1).toString()),
    s.appendChild(a),
    t.be())
  ) {
    const s = document.createElement("style");
    (s.innerHTML = t.css),
      (s.id = t.ye()),
      null != e.nn(L.wo) && s.setAttribute("nonce", e.nn(L.wo)),
      document.getElementsByTagName("head")[0].appendChild(s);
  }
  const m = t instanceof SlideUpMessage,
    l = ce(
      t,
      () => {
        import("../../Feed/ui/show-feed.js").then((t) => {
          e.so() ? t.showFeed() : r.error(_);
        });
      },
      (s) => {
        if (t.xe() && t.do()) {
          const o = document.createElement("div");
          if (
            ((o.className = "ab-page-blocker"),
            t.be() || (o.style.backgroundColor = ie(t.frameColor)),
            e.nn(L.ho) && (o.style.zIndex = e.nn(L.ho).toString()),
            a.appendChild(o),
            !e.nn(L.mh))
          ) {
            const e = new Date().valueOf();
            o.onclick = (o) => {
              new Date().valueOf() - e > InAppMessage.Ph &&
                (t.he(s), o.stopPropagation());
            };
          }
          a.appendChild(s), s.focus(), t.Ah(a);
        } else if (m) {
          const e = document.querySelectorAll(".ab-slideup");
          let o = null;
          for (let t = e.length - 1; t >= 0; t--)
            if (e[t] !== s) {
              o = e[t];
              break;
            }
          if (t.slideFrom === InAppMessage.SlideFrom.TOP) {
            let e = 0;
            null != o && (e = o.offsetTop + o.offsetHeight),
              (s.style.top = Math.max(e, 0) + "px");
          } else {
            let e = 0;
            null != o &&
              (e =
                (window.innerHeight || document.documentElement.clientHeight) -
                o.offsetTop),
              (s.style.bottom = Math.max(e, 0) + "px");
          }
        } else if (n && !e.nn(L.mh)) {
          const e = t;
          It(s) &&
            s.contentWindow &&
            s.contentWindow.addEventListener("keydown", function (t) {
              t.keyCode === at._h && e.closeMessage();
            });
        }
        logInAppMessageImpression(t),
          t.dismissType === InAppMessage.DismissType.AUTO_DISMISS &&
            setTimeout(() => {
              a.contains(s) && t.he(s);
            }, t.duration),
          "function" == typeof o && o();
      },
      (e, s) => {
        r.info(e), i.Ve(t.triggerId, s);
      },
      e.nn(L.yo),
      e.nn(L.ho),
      e.nn(L.wo),
      s,
    );
  return (n || m) && (a.appendChild(l), t.Ah(a)), !0;
}
