export const Browsers = {
  rO: "Chrome",
  eO: "Edge",
  oO: "Opera",
  Bg: "Safari",
  OO: "Firefox",
};
export const OperatingSystems = {
  xg: "Android",
  io: "iOS",
  kg: "Mac",
  Pg: "Windows",
};
