import e, { OPTIONS as L } from "../managers/braze-instance.js";
import X from "../util/browser-detector.js";
import nr from "./l10n-manager.js";
const je = {
  t: !1,
  i: null,
  m: () => {
    if ((je.o(), !je.i)) {
      let r = X.language,
        t = !1;
      e.nn(L.Xn) && ((r = e.nn(L.Xn)), (t = !0)), (je.i = new nr(r, t));
    }
    return je.i;
  },
  o: () => {
    je.t || (e.g(je), (je.t = !0));
  },
  destroy: () => {
    (je.i = null), (je.t = !1);
  },
};
export default je;
