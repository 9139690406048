import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import s from "../common/event-logger.js";
import e from "../managers/braze-instance.js";
import ir from "./feature-flags-provider-factory.js";
export function logFeatureFlagImpression(t) {
  if (!e.X()) return;
  if (!t) return !1;
  const o =
      "Not logging a feature flag impression. The feature flag was not part of any matching experiment.",
    n = ir.rr().Ci();
  if (!n[t]) return r.info(o), !1;
  const a = n[t].trackingString;
  if (!a) return r.info(o), !1;
  const f = ir.rr().Di();
  if (f[a])
    return (
      r.info(
        "Not logging another feature flag impression. This ID was already logged this session.",
      ),
      !1
    );
  (f[a] = !0), ir.rr().Ri(f);
  const g = { fid: t, fts: a };
  return s.q(i.Fr, g).L;
}
