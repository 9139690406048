import { STORAGE_KEYS as o } from "./storage-manager.js";
import T from "./subscription-manager.js";
import Gt from "../models/server-config.js";
import s from "../common/event-logger.js";
import { EventTypes as i } from "../../shared-lib/index.js";
import {
  CONTENT_CARDS_RATE_LIMIT_CAPACITY_DEFAULT as Vt,
  CONTENT_CARDS_RATE_LIMIT_REFILL_RATE_DEFAULT as Ht,
} from "../common/constants.js";
export default class Dt {
  constructor(t) {
    (this.u = t),
      (this.u = t),
      (this.tl = new T()),
      (this.el = new T()),
      (this.il = new T()),
      (this.sl = null),
      (this.rl = null);
  }
  ll() {
    if (null == this.rl) {
      const t = this.u.j(o.C.hl);
      this.rl = null != t ? Gt.En(t) : new Gt();
    }
    return this.rl;
  }
  ni() {
    return this.ll().ol;
  }
  ul(t) {
    if (null != t && null != t.config) {
      const e = t.config;
      if (e.time > this.ll().ol) {
        const t = (t) => (null == t ? this.ll().al : t),
          i = new Gt(
            e.time,
            e.events_blacklist,
            e.attributes_blacklist,
            e.purchases_blacklist,
            e.messaging_session_timeout,
            e.vapid_public_key,
            e.content_cards,
            e.feature_flags,
            t(e.global_request_rate_limit),
          );
        let s = !1;
        null != i.cl && this.In() !== i.cl && (s = !0);
        let r = !1;
        null != i.ml.enabled && this.ri() !== i.ml.enabled && (r = !0);
        let n = !1;
        null != i.mi.enabled && this.yi() !== i.mi.enabled && (n = !0),
          (this.rl = i),
          this.u.k(o.C.hl, i.Y()),
          s && this.tl.Dt(),
          r && this.el.Dt(),
          n && this.il.Dt();
      }
    }
  }
  Vn(t) {
    const e = this.tl.It(t);
    return this.sl && this.tl.removeSubscription(this.sl), (this.sl = e), e;
  }
  qs(t) {
    return this.el.It(t);
  }
  xi(t) {
    return this.il.It(t);
  }
  me(t) {
    return -1 !== this.ll().gl.indexOf(t);
  }
  hu(t) {
    return -1 !== this.ll().fl.indexOf(t);
  }
  Dr(t) {
    return -1 !== this.ll().bl.indexOf(t);
  }
  dl() {
    return this.ll().Cl;
  }
  In() {
    return this.ll().cl;
  }
  ri() {
    return this.ll().ml.enabled || !1;
  }
  Is() {
    const t = this.ll().ml.rate_limit;
    return !(!t || null == t.enabled) && t.enabled;
  }
  ci() {
    if (!this.Is()) return -1;
    const t = this.ll().ml.rate_limit;
    return null == t.capacity ? Vt : t.capacity <= 0 ? -1 : t.capacity;
  }
  fi() {
    if (!this.Is()) return -1;
    const t = this.ll().ml.rate_limit;
    return null == t.refill_rate ? Ht : t.refill_rate <= 0 ? -1 : t.refill_rate;
  }
  Rl() {
    const t = this.ll().al;
    return !(!t || null == t.enabled) && t.enabled;
  }
  pu() {
    if (!this.Rl()) return -1;
    const t = this.ll().al;
    return null == t.capacity || t.capacity < 10 ? -1 : t.capacity;
  }
  qu() {
    if (!this.Rl()) return -1;
    const t = this.ll().al;
    return null == t.refill_rate || t.refill_rate <= 0 ? -1 : t.refill_rate;
  }
  yi() {
    return this.ll().mi.enabled && null == this.zi()
      ? (s.q(i.Ms, { e: "Missing feature flag refresh_rate_limit." }), !1)
      : this.ll().mi.enabled || !1;
  }
  zi() {
    return this.ll().mi.refresh_rate_limit;
  }
}
