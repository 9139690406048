import kt from "./auth-manager.js";
import y from "../common/base-provider.js";
import X from "../util/browser-detector.js";
import Ot from "./device-manager.js";
import DeviceProperties from "../Core/device-properties.js";
import {
  isArray as j,
  keys as to,
  validateValueIsFromEnum as J,
  values as Pt,
} from "../util/code-utils.js";
import Rt from "./network-manager.js";
import Lt from "../request-controller.js";
import Dt from "./server-config-manager.js";
import Mt from "./session-manager.js";
import Q, { STORAGE_KEYS as o } from "./storage-manager.js";
import Bt from "./storage-manager-factory.js";
import T from "./subscription-manager.js";
import { TriggersProviderFactory as et } from "../triggers/triggers-provider-factory.js";
import bt from "../User/user-manager.js";
import { User } from "../User/index.js";
import { parseQueryStringKeyValues as ut } from "../util/url-utils.js";
import { WindowUtils as eo } from "../util/window-utils.js";
import { BRAZE_MUST_BE_INITIALIZED_ERROR as _ } from "../common/constants.js";
import { SupportedOptions as Wt, logger as r } from "../../shared-lib/index.js";
const L = {
  _o: "allowCrawlerActivity",
  Io: "baseUrl",
  Ao: "noCookies",
  So: "devicePropertyAllowlist",
  qa: "disablePushTokenMaintenance",
  No: "enableLogging",
  Oo: "enableSdkAuthentication",
  ka: "manageServiceWorkerExternally",
  Po: "minimumIntervalBetweenTriggerActionsInSeconds",
  Ro: "sessionTimeoutInSeconds",
  Lo: "appVersion",
  Do: "appVersionNumber",
  Ma: "serviceWorkerLocation",
  _a: "safariWebsitePushId",
  Xn: "localization",
  wo: "contentSecurityNonce",
  Mo: "allowUserSuppliedJavascript",
  ho: "inAppMessageZIndex",
  yo: "openInAppMessagesInNewTab",
  tn: "openCardsInNewTab",
  en: "openNewsFeedCardsInNewTab",
  mh: "requireExplicitInAppMessageDismissal",
  Uo: "doNotLoadFontAwesome",
  Bo: "sdkFlavor",
};
class zt {
  constructor() {
    (this.an = ""),
      (this.Wo = ""),
      (this.Vo = void 0),
      (this.Go = null),
      (this.hn = null),
      (this.qt = null),
      (this.Ei = null),
      (this.yt = null),
      (this.di = null),
      (this.u = null),
      (this.wt = null),
      (this.Ko = ""),
      (this.$o = !1),
      (this.qo = !1),
      (this.Jo = new T()),
      (this.Yo = new T()),
      (this.options = {}),
      (this.Ho = []),
      (this.Xo = []),
      (this._e = []),
      (this.Wo = "5.1.1");
  }
  Zo(t) {
    this.Jo.It(t);
  }
  Rh(t) {
    this.Yo.It(t);
  }
  initialize(t, i) {
    if (this.so())
      return r.info("Braze has already been initialized with an API key."), !0;
    this.options = i || {};
    let s = this.nn(L.No);
    const e = ut(eo.Tn());
    if (
      (e && "true" === e.brazeLogging && (s = !0),
      r.init(s),
      r.info(
        `Initialization Options: ${JSON.stringify(this.options, null, 2)}`,
      ),
      null == t || "" === t || "string" != typeof t)
    )
      return r.error("Braze requires a valid API key to be initialized."), !1;
    this.an = t;
    let n = this.nn(L.Io);
    if (null == n || "" === n || "string" != typeof n)
      return r.error("Braze requires a valid baseUrl to be initialized."), !1;
    !1 === /^https?:/.test(n) && (n = `https://${n}`);
    const h = n;
    if (
      ((n = document.createElement("a")),
      (n.href = h),
      "/" === n.pathname && (n = `${n}api/v3`),
      (this.Ko = n.toString()),
      X.Qo && !this.nn(L._o))
    )
      return (
        r.info("Ignoring activity from crawler bot " + navigator.userAgent),
        (this.qo = !0),
        !1
      );
    const a = this.nn(L.Ao) || !1;
    if (
      ((this.u = Bt.Wh(t, a)), a && this.u.Vh(t), new Q.ee(null, !0).br(o.se))
    )
      return (
        r.info("Ignoring all activity due to previous opt out"),
        (this.qo = !0),
        !1
      );
    for (const t of to(this.options))
      -1 === Pt(Wt).indexOf(t) &&
        r.warn(`Ignoring unknown initialization option '${t}'.`);
    const l = ["mparticle", "wordpress", "tealium"];
    if (null != this.nn(L.Bo)) {
      const t = this.nn(L.Bo);
      -1 !== l.indexOf(t)
        ? (this.Vo = t)
        : r.error("Invalid sdk flavor passed: " + t);
    }
    let u = this.nn(Wt.So);
    if (null != u)
      if (j(u)) {
        const t = [];
        for (let i = 0; i < u.length; i++)
          J(
            DeviceProperties,
            u[i],
            "devicePropertyAllowlist contained an invalid value.",
            "DeviceProperties",
          ) && t.push(u[i]);
        u = t;
      } else
        r.error(
          "devicePropertyAllowlist must be an array. Defaulting to all properties.",
        ),
          (u = null);
    (this.hn = new Ot(this.u, u)),
      (this.yt = new Dt(this.u)),
      (this.wt = new bt(this.yt, this.u)),
      (this.di = new Mt(this.u, this.wt, this.yt, this.nn(L.Ro)));
    const f = new T();
    return (
      (this.Go = new kt(this.u, this.nn(L.Oo), f)),
      this.$t(f),
      (this.qt = new Rt(
        this.hn,
        this.u,
        this.Go,
        this.wt,
        this.di,
        this.yt,
        this.an,
        this.Ko,
        this.Wo,
        this.Vo || "",
        this.nn(L.Lo),
        this.nn(L.Do),
      )),
      (this.Ei = new Lt(
        this.an,
        this.Ko,
        this.di,
        this.hn,
        this.wt,
        this.yt,
        this.u,
        (t) => {
          if (this.so()) for (const i of this.gr()) i.Rs(t);
        },
        this.Go,
        this.qt,
      )),
      this.Ei.initialize(),
      a || this.u.Kh(),
      r.info(
        `Initialized for the Braze backend at "${this.nn(
          L.Io,
        )}" with API key "${this.an}".`,
      ),
      et.o(),
      this.yt.xi(() => {
        var t;
        this.$o &&
          (null === (t = this.yt) || void 0 === t ? void 0 : t.yi()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.$o) return;
            (0, t.default)();
          });
      }),
      this.Ei.mr(() => {
        var t;
        this.$o &&
          (null === (t = this.yt) || void 0 === t ? void 0 : t.yi()) &&
          import("../FeatureFlags/refresh-feature-flags.js").then((t) => {
            if (!this.$o) return;
            (0, t.default)(void 0, void 0, !0);
          });
      }),
      this.Jo.Dt(this.options),
      (this.$o = !0),
      !0
    );
  }
  destroy(t) {
    if ((r.destroy(), this.so())) {
      this.Yo.Dt(), this.Yo.removeAllSubscriptions();
      for (const t of this.Ho) t.destroy();
      this.Ho = [];
      for (const t of this.Xo) t.clearData(!1);
      (this.Xo = []),
        this.removeAllSubscriptions(),
        (this._e = []),
        null != this.Ei && this.Ei.destroy(),
        (this.Ei = null),
        (this.Go = null),
        (this.hn = null),
        (this.qt = null),
        (this.yt = null),
        (this.di = null),
        (this.wt = null),
        (this.options = {}),
        (this.Vo = void 0),
        (this.$o = !1),
        (this.qo = !1),
        t && (this.u = null);
    }
  }
  X() {
    return !this.$h() && (!!this.so() || (console.warn(_), !1));
  }
  ba() {
    return this.an;
  }
  Sr() {
    return this.Go;
  }
  Vs() {
    return this.Ko;
  }
  te() {
    return this.hn;
  }
  nr() {
    return this.qt;
  }
  nn(t) {
    return this.options[t];
  }
  gr() {
    return this.Xo;
  }
  cr() {
    return this.Ei;
  }
  ir() {
    return this.yt;
  }
  zr() {
    return this.di;
  }
  l() {
    return this.u;
  }
  pr() {
    if (this.wt && this.Ei) return new User(this.wt, this.Ei);
  }
  er() {
    return this.wt;
  }
  tr() {
    return !0 === this.nn(L.Mo);
  }
  g(t) {
    let i = !1;
    for (const s of this.Ho) s === t && (i = !0);
    i || this.Ho.push(t);
  }
  ar(t) {
    let i = !1;
    for (const s of this.Xo) s.constructor === t.constructor && (i = !0);
    t instanceof y && !i && this.Xo.push(t);
  }
  $t(t) {
    t instanceof T && this._e.push(t);
  }
  removeAllSubscriptions() {
    if (this.X()) for (const t of this._e) t.removeAllSubscriptions();
  }
  removeSubscription(t) {
    if (this.X()) for (const i of this._e) i.removeSubscription(t);
  }
  oe(t) {
    this.qo = t;
  }
  so() {
    return this.$o;
  }
  $h() {
    return this.qo;
  }
  Ss() {
    return this.Wo;
  }
}
const e = new zt();
export { e as default, zt as BrazeSdkInstance, L as OPTIONS };
