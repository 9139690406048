import { isArray as j, isEqual as ii } from "../util/code-utils.js";
import ti from "../models/push-token.js";
import { logger as r, EncodingUtils as ei } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "../managers/storage-manager.js";
import { User } from "../User/index.js";
import { WindowUtils as eo } from "../util/window-utils.js";
import vt from "./utils/push-utils.js";
import { getErrorMessage as si } from "../util/error-utils.js";
export default class ea {
  constructor(i, t, e, s, r, n, o, u, a, h) {
    (this.un = i),
      (this.an = t),
      (this.hn = e),
      (this.cn = r),
      (this.fn = n),
      (this.yt = o),
      (this.ln = u),
      (this.dn = a),
      (this.u = h),
      (this.un = i),
      (this.an = t),
      (this.hn = e),
      (this.pn = s + "/safari/" + t),
      (this.cn = r || "/service-worker.js"),
      (this.fn = n),
      (this.yt = o),
      (this.ln = u || !1),
      (this.dn = a || !1),
      (this.u = h),
      (this.bn = vt.mn()),
      (this.yn = vt.gn());
  }
  vn() {
    return this.dn;
  }
  wn(i, t, e, s, n) {
    i.unsubscribe()
      .then((i) => {
        i
          ? this.kn(t, e, s, n)
          : (r.error("Failed to unsubscribe device from push."),
            "function" == typeof n && n(!1));
      })
      .catch((i) => {
        r.error("Push unsubscription error: " + i),
          "function" == typeof n && n(!1);
      });
  }
  Pn(i, t, e) {
    var s;
    const n = ((i) => {
      if ("string" == typeof i) return i;
      if (0 !== i.endpoint.indexOf("https://android.googleapis.com/gcm/send"))
        return i.endpoint;
      let t = i.endpoint;
      const e = i;
      return (
        e.Dn &&
          -1 === i.endpoint.indexOf(e.Dn) &&
          (t = i.endpoint + "/" + e.Dn),
        t
      );
    })(i);
    let o = null,
      u = null;
    const a = i;
    if (null != a.getKey)
      try {
        const i = Array.from(new Uint8Array(a.getKey("p256dh"))),
          t = Array.from(new Uint8Array(a.getKey("auth")));
        (o = btoa(String.fromCharCode.apply(null, i))),
          (u = btoa(String.fromCharCode.apply(null, t)));
      } catch (i) {
        r.error(si(i));
      }
    const h = ((i) => {
      let t;
      return i.options &&
        (t = i.options.applicationServerKey) &&
        t.byteLength &&
        t.byteLength > 0
        ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(t))))
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
        : null;
    })(a);
    null === (s = this.un) || void 0 === s || s.Sn(n, t, o, u, h),
      n && "function" == typeof e && e(n, o, u);
  }
  An() {
    var i;
    null === (i = this.un) || void 0 === i || i.jn(!0);
  }
  xn(i, t) {
    var e;
    null === (e = this.un) || void 0 === e || e.jn(!1),
      r.info(i),
      "function" == typeof t && t(!1);
  }
  Un(i, t, e, s) {
    var n;
    if ("default" === t.permission)
      try {
        window.safari.pushNotification.requestPermission(
          this.pn,
          i,
          {
            api_key: this.an,
            device_id:
              (null === (n = this.hn) || void 0 === n ? void 0 : n.ce().id) ||
              "",
          },
          (t) => {
            "granted" === t.permission &&
              this.un &&
              this.un.setPushNotificationSubscriptionType(
                User.NotificationSubscriptionTypes.OPTED_IN,
              ),
              this.Un(i, t, e, s);
          },
        );
      } catch (i) {
        this.xn("Could not request permission for push: " + i, s);
      }
    else
      "denied" === t.permission
        ? this.xn(
            "The user has blocked notifications from this site, or Safari push is not configured in the Braze dashboard.",
            s,
          )
        : "granted" === t.permission &&
          (r.info("Device successfully subscribed to push."),
          this.Pn(t.deviceToken, new Date(), e));
  }
  requestPermission(i, t, e) {
    const s = (s) => {
      switch (s) {
        case "granted":
          return void ("function" == typeof i && i());
        case "default":
          return void ("function" == typeof t && t());
        case "denied":
          return void ("function" == typeof e && e());
        default:
          r.error("Received unexpected permission result " + s);
      }
    };
    let n = !1;
    const o = window.Notification.requestPermission((i) => {
      n && s(i);
    });
    o
      ? o.then((i) => {
          s(i);
        })
      : (n = !0);
  }
  kn(i, t, e, s) {
    const n = { userVisibleOnly: !0 };
    null != t && (n.applicationServerKey = t),
      i.pushManager
        .subscribe(n)
        .then((i) => {
          r.info("Device successfully subscribed to push."),
            this.Pn(i, new Date(), e);
        })
        .catch((i) => {
          vt.isPushBlocked()
            ? (r.info("Permission for push notifications was denied."),
              "function" == typeof s && s(!1))
            : (r.error("Push subscription failed: " + i),
              "function" == typeof s && s(!0));
        });
  }
  Wn() {
    return this.ln
      ? navigator.serviceWorker.getRegistration(this.cn)
      : navigator.serviceWorker.register(this.cn).then(() =>
          navigator.serviceWorker.ready.then(
            (i) => (
              i &&
                "function" == typeof i.update &&
                i.update().catch((i) => {
                  r.info("ServiceWorker update failed: " + i);
                }),
              i
            ),
          ),
        );
  }
  _n(i) {
    this.ln ||
      (i.unregister(), r.info("Service worker successfully unregistered."));
  }
  subscribe(i, t) {
    if (!vt.isPushSupported())
      return r.info(ea.Nn), void ("function" == typeof t && t(!1));
    if (this.bn) {
      if (!this.ln && null != window.location) {
        let i = this.cn;
        -1 === i.indexOf(window.location.host) &&
          (i = window.location.host + i),
          -1 === i.indexOf(window.location.protocol) &&
            (i = window.location.protocol + "//" + i);
        const e = i.substr(0, i.lastIndexOf("/") + 1);
        if (0 !== eo.Tn().indexOf(e))
          return (
            r.error(
              "Cannot subscribe to push from a path higher than the service worker location (tried to subscribe from " +
                window.location.pathname +
                " but service worker is at " +
                i +
                ")",
            ),
            void ("function" == typeof t && t(!0))
          );
      }
      if (vt.isPushBlocked())
        return void this.xn(
          "Notifications from this site are blocked. This may be a temporary embargo or a permanent denial.",
          t,
        );
      if (this.yt && !this.yt.In() && 0 === this.yt.ni())
        return (
          r.info(
            "Waiting for VAPID key from server config before subscribing to push.",
          ),
          void this.yt.Vn(() => {
            this.subscribe(i, t);
          })
        );
      const e = () => {
          r.info("Permission for push notifications was denied."),
            "function" == typeof t && t(!1);
        },
        s = () => {
          let i = "Permission for push notifications was ignored.";
          vt.isPushBlocked() &&
            (i +=
              " The browser has automatically blocked further permission requests for a period (probably 1 week)."),
            r.info(i),
            "function" == typeof t && t(!0);
        },
        n = vt.isPushPermissionGranted(),
        u = () => {
          !n &&
            this.un &&
            this.un.setPushNotificationSubscriptionType(
              User.NotificationSubscriptionTypes.OPTED_IN,
            ),
            this.Wn()
              .then((e) => {
                if (null == e)
                  return (
                    r.error(
                      "No service worker registration. Set the `manageServiceWorkerExternally` initialization option to false or ensure that your service worker is registered before calling registerPush.",
                    ),
                    void ("function" == typeof t && t(!0))
                  );
                e.pushManager
                  .getSubscription()
                  .then((s) => {
                    var n;
                    let u = null;
                    if (
                      (null !=
                        (null === (n = this.yt) || void 0 === n
                          ? void 0
                          : n.In()) && (u = ei.qn(this.yt.In())),
                      s)
                    ) {
                      let n,
                        a = null,
                        h = null;
                      if ((this.u && (n = this.u.j(o.C.zn)), n && !j(n))) {
                        let i;
                        try {
                          i = ti.En(n).Cn;
                        } catch (t) {
                          i = null;
                        }
                        null == i ||
                          isNaN(i.getTime()) ||
                          0 === i.getTime() ||
                          ((a = i),
                          (h = new Date(a)),
                          h.setMonth(a.getMonth() + 6));
                      }
                      null != u &&
                      s.options &&
                      s.options.applicationServerKey &&
                      s.options.applicationServerKey.byteLength &&
                      s.options.applicationServerKey.byteLength > 0 &&
                      !ii(u, new Uint8Array(s.options.applicationServerKey))
                        ? (s.options.applicationServerKey.byteLength > 12
                            ? r.info(
                                "Device was already subscribed to push using a different VAPID provider, creating new subscription.",
                              )
                            : r.info(
                                "Attempting to upgrade a gcm_sender_id-based push registration to VAPID - depending on the browser this may or may not result in the same gcm_sender_id-based subscription.",
                              ),
                          this.wn(s, e, u, i, t))
                        : s.expirationTime &&
                          new Date(s.expirationTime).valueOf() <=
                            new Date().valueOf()
                        ? (r.info(
                            "Push subscription is expired, creating new subscription.",
                          ),
                          this.wn(s, e, u, i, t))
                        : n && j(n)
                        ? this.wn(s, e, u, i, t)
                        : null == h
                        ? (r.info(
                            "No push subscription creation date found, creating new subscription.",
                          ),
                          this.wn(s, e, u, i, t))
                        : h.valueOf() <= new Date().valueOf()
                        ? (r.info(
                            "Push subscription older than 6 months, creating new subscription.",
                          ),
                          this.wn(s, e, u, i, t))
                        : (r.info(
                            "Device already subscribed to push, sending existing subscription to backend.",
                          ),
                          this.Pn(s, a, i));
                    } else this.kn(e, u, i, t);
                  })
                  .catch((i) => {
                    r.error("Error checking current push subscriptions: " + i);
                  });
              })
              .catch((i) => {
                r.error("ServiceWorker registration failed: " + i);
              });
        };
      this.requestPermission(u, s, e);
    } else if (this.yn) {
      if (null == this.fn || "" === this.fn)
        return (
          r.error(
            "You must supply the safariWebsitePushId initialization option in order to use registerPush on Safari",
          ),
          void ("function" == typeof t && t(!0))
        );
      const e = window.safari.pushNotification.permission(this.fn);
      this.Un(this.fn, e, i, t);
    }
  }
  unsubscribe(i, t) {
    if (!vt.isPushSupported())
      return r.info(ea.Nn), void ("function" == typeof t && t());
    this.bn
      ? navigator.serviceWorker.getRegistration().then((e) => {
          e
            ? e.pushManager
                .getSubscription()
                .then((s) => {
                  s &&
                    (this.An(),
                    s
                      .unsubscribe()
                      .then((s) => {
                        s
                          ? (r.info(
                              "Device successfully unsubscribed from push.",
                            ),
                            "function" == typeof i && i())
                          : (r.error("Failed to unsubscribe device from push."),
                            "function" == typeof t && t()),
                          this._n(e);
                      })
                      .catch((i) => {
                        r.error("Push unsubscription error: " + i),
                          "function" == typeof t && t();
                      }));
                })
                .catch((i) => {
                  r.error("Error unsubscribing from push: " + i),
                    "function" == typeof t && t();
                })
            : (r.info("Device already unsubscribed from push."),
              "function" == typeof i && i());
        })
      : this.yn &&
        (this.An(),
        r.info("Device unsubscribed from push."),
        "function" == typeof i && i());
  }
}
ea.Nn = "Push notifications are not supported in this browser.";
