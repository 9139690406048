import InAppMessage from "./models/in-app-message.js";
import e from "../managers/braze-instance.js";
import ControlMessage from "./models/control-message.js";
import { logger as r } from "../../shared-lib/index.js";
import se from "./in-app-message-manager-factory.js";
export function deferInAppMessage(s) {
  if (e.X())
    return s instanceof ControlMessage
      ? (r.info("Not deferring since this is a ControlMessage."), !1)
      : s instanceof InAppMessage
      ? se.m().de(s)
      : (r.info("Not an instance of InAppMessage, ignoring."), !1);
}
