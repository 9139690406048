import { logger as r } from "../../shared-lib/index.js";
export default class FeatureFlag {
  constructor(t, r = !1, e = {}, s) {
    (this.id = t),
      (this.enabled = r),
      (this.properties = e),
      (this.trackingString = s),
      (this.id = t),
      (this.enabled = r),
      (this.properties = e),
      (this.trackingString = s);
  }
  getStringProperty(t) {
    const r = this.properties[t];
    return null == r
      ? (this.Er(t), null)
      : this.Ir(r)
      ? r.value
      : (this.Nr("string"), null);
  }
  getNumberProperty(t) {
    const r = this.properties[t];
    return null == r
      ? (this.Er(t), null)
      : this.Tr(r)
      ? r.value
      : (this.Nr("number"), null);
  }
  getBooleanProperty(t) {
    const r = this.properties[t];
    return null == r
      ? (this.Er(t), null)
      : this.Ar(r)
      ? r.value
      : (this.Nr("boolean"), null);
  }
  Y() {
    const t = {};
    return (
      (t[FeatureFlag.tt.ht] = this.id),
      (t[FeatureFlag.tt.le] = this.enabled),
      (t[FeatureFlag.tt.pe] = this.properties),
      (t[FeatureFlag.tt.Fe] = this.trackingString),
      t
    );
  }
  Nr(t) {
    r.info(`Property is not of type ${t}.`);
  }
  Er(t) {
    r.info(`${t} not found in feature flag properties.`);
  }
  Ir(t) {
    return "string" === t.type && "string" == typeof t.value;
  }
  Tr(t) {
    return "number" === t.type && "number" == typeof t.value;
  }
  Ar(t) {
    return "boolean" === t.type && "boolean" == typeof t.value;
  }
}
(FeatureFlag.tt = { ht: "id", le: "e", pe: "pr", Fe: "fts" }),
  (FeatureFlag.Rt = { ht: "id", le: "enabled", pe: "properties", Fe: "fts" });
