import qt from "../models/backend-errors.js";
import ue from "../models/braze-event.js";
import {
  convertMsToSeconds as h,
  convertSecondsToMs as Xt,
} from "../util/date-utils.js";
import s from "../common/event-logger.js";
import { isArray as j, isEqual as ii } from "../util/code-utils.js";
import { logger as r, EventTypes as i } from "../../shared-lib/index.js";
import { STORAGE_KEYS as o } from "./storage-manager.js";
import S from "../util/request-header-utils.js";
import { LAST_REQUEST_TO_ENDPOINT_MS_AGO_DEFAULT as Kt } from "../common/constants.js";
export default class Rt {
  constructor(t, e, s, i, r, n, o, a, h, u, l, c) {
    (this.hn = t),
      (this.u = e),
      (this.Go = s),
      (this.wt = i),
      (this.di = r),
      (this.yt = n),
      (this.an = o),
      (this.Ko = a),
      (this.Wo = h),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Ra = c),
      (this.Ba = (t) => (null == t ? "" : `${t} `)),
      (this.hn = t),
      (this.u = e),
      (this.Go = s),
      (this.wt = i),
      (this.di = r),
      (this.yt = n),
      (this.an = o),
      (this.Ko = a),
      (this.Wo = h),
      (this.Vo = u),
      (this.appVersion = l),
      (this.Ra = c),
      (this.Xa = ["npm"]);
  }
  Ps(t, e = !1, s = !1) {
    const i = this.hn.ce(!s),
      r = i.Gr(),
      n = this.u.j(o.C.Ja);
    ii(n, r) || (t.device = r),
      (t.api_key = this.an),
      (t.time = h(new Date().valueOf(), !0));
    const a = this.u.j(o.C.Na) || [],
      u = this.u.j(o.C.$a) || "";
    if (
      (this.Xa.length > 0 &&
        (!ii(a, this.Xa) || u !== this.di.ki()) &&
        (t.sdk_metadata = this.Xa),
      (t.sdk_version = this.Wo),
      this.Vo && (t.sdk_flavor = this.Vo),
      (t.app_version = this.appVersion),
      (t.app_version_code = this.Ra),
      (t.device_id = i.id),
      e)
    ) {
      const e = this.wt.getUserId();
      null != e && (t.user_id = e);
    }
    return t;
  }
  Ws(t, e, n) {
    const o = e.auth_error,
      a = e.error;
    if (!o && !a) return !0;
    if (o) {
      let e;
      this.Go.Gh();
      const s = { errorCode: o.error_code };
      for (const t of n)
        j(t) && "X-Braze-Auth-Signature" === t[0] && (s.signature = t[1]);
      t.respond_with && t.respond_with.user_id
        ? (s.userId = t.respond_with.user_id)
        : t.user_id && (s.userId = t.user_id);
      const i = o.reason;
      return (
        i
          ? ((s.reason = i), (e = `due to ${i}`))
          : (e = `with error code ${o.error_code}.`),
        this.Go.fh() ||
          (e +=
            ' Please use the "enableSdkAuthentication" initialization option to enable authentication.'),
        r.error(`SDK Authentication failed ${e}`),
        this.Ea(t.events || [], t.attributes || []),
        this.Go.yh(s),
        !1
      );
    }
    if (a) {
      let n,
        o = a;
      switch (o) {
        case qt.Ka:
          return (
            (n = "Received successful response with empty body."),
            s.q(i.Ms, { e: n }),
            r.info(n),
            !1
          );
        case qt.La:
          return (
            (n = "Received successful response with invalid JSON"),
            s.q(i.Ms, { e: n + ": " + e.response }),
            r.info(n),
            !1
          );
        case qt.Pa:
          o = `The API key "${t.api_key}" is invalid for the baseUrl ${this.Ko}`;
          break;
        case qt.Ua:
          o =
            "Sorry, we are not currently accepting your requests. If you think this is in error, please contact us.";
          break;
        case qt.Ha:
          o = "No device identifier. Please contact support@braze.com";
      }
      r.error("Backend error: " + o);
    }
    return !1;
  }
  Wa(t, e, s, i) {
    return !!((t && 0 !== t.length) || (e && 0 !== e.length) || s || i);
  }
  Ya(t, e, s, i, r = !1) {
    const n = [],
      o = (t) => t || "",
      a = o(this.wt.getUserId());
    let h = this._r(t, e);
    const u = [],
      l = [];
    let c,
      d = null;
    if (s.length > 0) {
      const t = [];
      for (const e of s) {
        if (((c = e.Gr()), this.Go.fh())) {
          if (a && !c.user_id) {
            d || (d = {}), d.events || (d.events = []), d.events.push(c);
            continue;
          }
          if (o(c.user_id) !== a) {
            l.push(c);
            continue;
          }
        }
        t.push(c);
      }
      t.length > 0 && (h.events = t);
    }
    if (i.length > 0) {
      const t = [];
      for (const e of i)
        e && (this.Go.fh() && o(e.user_id) !== a ? u.push(e) : t.push(e));
      t.length > 0 && (h.attributes = t);
    }
    if ((this.Ea(l, u), (h = this.Ps(h, !0, r)), d)) {
      d = this.Ps(d, !1, r);
      const t = { requestData: d, headers: this._s(d, S.Gs.Qa) };
      n.push(t);
    }
    if (h && !this.Wa(h.events, h.attributes, t, e)) return d ? n : null;
    const f = { requestData: h, headers: this._s(h, S.Gs.Qa) };
    return n.push(f), n;
  }
  Ea(t, e) {
    if (t) {
      const e = [];
      for (const s of t) {
        const t = ue.fromJson(s);
        (t.time = Xt(t.time)), e.push(t);
      }
      this.u.zo(e);
    }
    if (e) for (const t of e) this.u.Va(t);
  }
  Zs(t, e) {
    let s = "HTTP error ";
    null != t && (s += t + " "), (s += e), r.error(s);
  }
  qr(t) {
    return s.q(i.Za, { n: t });
  }
  _r(t, e, s) {
    const i = {};
    t && (i.feed = !0), e && (i.triggers = !0);
    const r = null != s ? s : this.wt.getUserId();
    return (
      r && (i.user_id = r),
      (i.config = { config_time: this.yt.ni() }),
      { respond_with: i }
    );
  }
  Xh(t) {
    const e = new Date().valueOf();
    let s = Kt.toString();
    const i = S.Yh(this.u, t);
    if (-1 !== i) {
      s = (e - i).toString();
    }
    return s;
  }
  _s(t, e, s = !1) {
    const i = [["X-Braze-Api-Key", this.an]],
      r = this.Xh(e);
    i.push(["X-Braze-Last-Req-Ms-Ago", r]);
    const n = S.Jh(this.u, e).toString();
    i.push(["X-Braze-Req-Attempt", n]);
    let a = !1;
    if (
      (null != t.respond_with &&
        t.respond_with.triggers &&
        (i.push(["X-Braze-TriggersRequest", "true"]), (a = !0)),
      null != t.respond_with &&
        t.respond_with.feed &&
        (i.push(["X-Braze-FeedRequest", "true"]), (a = !0)),
      e === S.Gs.Bs)
    ) {
      i.push(["X-Braze-ContentCardsRequest", "true"]);
      let t = this.u.j(o.C.As);
      (t && s) || ((t = 0), this.u.k(o.C.As, t)),
        i.push(["BRAZE-SYNC-RETRY-COUNT", t.toString()]),
        (a = !0);
    }
    if (
      (e === S.Gs.qi &&
        (i.push(["X-Braze-FeatureFlagsRequest", "true"]), (a = !0)),
      a && i.push(["X-Braze-DataRequest", "true"]),
      this.Go.fh())
    ) {
      const t = this.Go.wh();
      null != t && i.push(["X-Braze-Auth-Signature", t]);
    }
    return i;
  }
  Qh(t, e, s, i) {
    window.setTimeout(() => {
      r.info(`Retrying rate limited ${this.Ba(i)}SDK request.`),
        this.Hs(e, s, i);
    }, t);
  }
  Hs(t, e, s) {
    const i = this.Zh();
    if (!i.au)
      return (
        r.info(
          `${this.Ba(
            s,
          )}SDK request being rate limited. Request will be retried in ${Math.trunc(
            i.du / 1e3,
          )} seconds.`,
        ),
        void this.Qh(i.du, t, e, s)
      );
    this.u.k(o.C.fu, new Date().valueOf());
    const n = t.device;
    n && n.os_version instanceof Promise
      ? n.os_version.then((s) => {
          (t.device.os_version = s), e();
        })
      : e();
  }
  li(t) {
    var e;
    null === (e = this.u) || void 0 === e || e.k(o.C.Ru, t);
  }
  ui() {
    var t;
    return null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.Ru);
  }
  vu(t, e, s, i) {
    let r = this.ui();
    (null == r || isNaN(r)) && (r = e);
    const n = (t - i) / 1e3;
    return (r = Math.min(r + n / s, e)), r;
  }
  gu(t, e) {
    return Math.max(0, (1 - t) * e * 1e3);
  }
  Zh() {
    var t, e, s;
    const i = { au: !0, du: 0 },
      r = null === (t = this.u) || void 0 === t ? void 0 : t.j(o.C.fu);
    if (null == r || isNaN(r)) return i;
    const n = (null === (e = this.yt) || void 0 === e ? void 0 : e.pu()) || -1,
      a = (null === (s = this.yt) || void 0 === s ? void 0 : s.qu()) || -1;
    if (-1 === n || -1 === a) return i;
    const h = new Date().valueOf();
    let u = this.vu(h, n, a, r);
    return u < 1
      ? ((i.au = !1), (i.du = this.gu(u, a)), i)
      : ((u = Math.trunc(u) - 1), this.li(u), i);
  }
  Xs() {
    this.Go.Xs();
  }
  Vs() {
    return this.Ko;
  }
  addSdkMetadata(t) {
    for (const e of t) -1 === this.Xa.indexOf(e) && this.Xa.push(e);
  }
}
