import {
  GLOBAL_RATE_LIMIT_CAPACITY_DEFAULT as Qt,
  GLOBAL_RATE_LIMIT_REFILL_RATE_DEFAULT as Yt,
} from "../common/constants.js";
export default class Gt {
  constructor(
    t = 0,
    i = [],
    s = [],
    h = [],
    e = null,
    l = null,
    r = { enabled: !1 },
    a = { enabled: !1, refresh_rate_limit: void 0 },
    n = { enabled: !0, capacity: Qt, refill_rate: Yt },
  ) {
    (this.ol = t),
      (this.gl = i),
      (this.fl = s),
      (this.bl = h),
      (this.Cl = e),
      (this.cl = l),
      (this.ml = r),
      (this.mi = a),
      (this.al = n),
      (this.ol = t),
      (this.gl = i),
      (this.fl = s),
      (this.bl = h),
      (this.Cl = e),
      (this.cl = l),
      (this.ml = r),
      (this.mi = a),
      (this.al = n);
  }
  Y() {
    return {
      s: "5.1.1",
      l: this.ol,
      e: this.gl,
      a: this.fl,
      p: this.bl,
      m: this.Cl,
      v: this.cl,
      c: this.ml,
      f: this.mi,
      grl: this.al,
    };
  }
  static En(t) {
    let i = t.l;
    return (
      "5.1.1" !== t.s && (i = 0),
      new Gt(i, t.e, t.a, t.p, t.m, t.v, t.c, t.f, t.grl)
    );
  }
}
