const vt = {
  mn: () =>
    "serviceWorker" in navigator &&
    "undefined" != typeof ServiceWorkerRegistration &&
    "showNotification" in ServiceWorkerRegistration.prototype &&
    "PushManager" in window,
  gn: () =>
    "safari" in window &&
    "pushNotification" in window.safari &&
    "function" == typeof window.safari.pushNotification.permission &&
    "function" == typeof window.safari.pushNotification.requestPermission,
  isPushSupported: () => vt.mn() || vt.gn(),
  isPushBlocked: () => {
    const i =
        vt.isPushSupported() &&
        "Notification" in window &&
        null != window.Notification &&
        null != window.Notification.permission &&
        "denied" === window.Notification.permission,
      n =
        vt.isPushSupported() &&
        (!("Notification" in window) || null == window.Notification);
    return i || n;
  },
  isPushPermissionGranted: () =>
    vt.isPushSupported() &&
    "Notification" in window &&
    null != window.Notification &&
    null != window.Notification.permission &&
    "granted" === window.Notification.permission,
  Yi: () =>
    !vt.isPushBlocked() &&
    vt.isPushSupported() &&
    !vt.isPushPermissionGranted(),
};
export default vt;
